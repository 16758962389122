import { Controller } from "stimulus"

export default class extends Controller {
  connect() {

    // Arrow function used to preserve "this"
    // Needs to be wrapped in a setTimeout function to show slide down animation.
    setTimeout(() => {
      this.element.classList.remove("-translate-y-full", "opacity-0");
    }, 0);

    setTimeout(() => {
      this.element.classList.add("-translate-y-full", "opacity-0");
    }, 5000);
  }
}
