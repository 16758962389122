import { Controller } from "stimulus";
let debounce = require('lodash/debounce');

export default class extends Controller {
  static targets = [ "field", "connectedField", "hidden", "editable" ]

  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this
    this.autosave = debounce(this.autosave, 400).bind(this)
    this.quickAutosave = debounce(this.quickAutosave, 400).bind(this)
  }

  updateHidden(){
    const formButtons = parent.document.getElementById('form-buttons-container');
    
    // Update value field.
    this.hiddenTarget.value = this.editableTarget.innerHTML;

    // Set form to changed.
    this.hiddenTarget.closest('form').dataset.changed = true;

    const hasChanged = this.hiddenTarget.dataset.saved !== this.hiddenTarget.value

    if (hasChanged){ 
      formButtons.clickAppear.showHiddenElement();
    } else {
      formButtons.clickAppear.hideHiddenElement();
    }
  }

  formChanged(){
    const value = this.editableTarget.value;
    const oldvalue = this.editableTarget.dataset.oldValue;;
    const formButtons = parent.document.getElementById('form-buttons-container');
    const hasChanged = value !== oldvalue;
    const form = this.editableTarget.closest('form');

    // Add modified class to field
    if (hasChanged){
      this.editableTarget.classList.add("modified-input");
    } else {
      this.editableTarget.classList.remove("modified-input");
    }
    // Query all fields with modified class
    const modifiedInputs = form.querySelectorAll('.modified-input');
    
    if (modifiedInputs.length){
      form.classList.add("has-changed");
      formButtons.clickAppear.showHiddenElement();
    } else {
      form.classList.remove("has-changed");
      formButtons.clickAppear.hideHiddenElement();
    }
  }

  hexToRgbA(hex){
      var c;
      if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
          c= hex.substring(1).split('');
          if(c.length== 3){
              c= [c[0], c[0], c[1], c[1], c[2], c[2]];
          }
          c= '0x'+c.join('');
          return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',0.1)';
      }
      throw new Error('Bad Hex');
  }

  saveChangesMultiple(){
    const form = this.element.closest('form');
    form.formSubmit.submit();
  }

  saveChanges(){
    const form = this.element.closest('form');
    form.formSubmit.submit();
  }

  autosave(){
    const form = this.element.closest('form');
    form.formSubmit.submit();
  }

  quickAutosave(){
    const form = this.element.closest('form');
    form.formSubmit.submit();
  }

  reset(event){
    const settingContainer = event.target.closest('.lp-setting-tab-container')
    settingContainer.querySelectorAll('.lp-field').forEach(field =>{
      if (field.dataset.default && field.value !== field.dataset.default){
        const controllerField = field.closest('.field')

        // Change back to default
        field.value = field.dataset.default;

        // Animate change
        if (controllerField.dataset.controller.includes('form-field')){
          controllerField.formField.animate();
          if (field.dataset.type == "range"){
            field.closest('.lp-range-container').rangeSlider.slide();
          }
        }
        
      }
    });

    // Submit change
    settingContainer.closest('form').formSubmit.submit();
  }

  animateButton(){
    const name = this.fieldTarget.dataset.key;
    const elementSelector = this.fieldTarget.dataset.key2;
    let elementSelectorArray = []
    
    if (elementSelector){
      // Prefix selectors
      elementSelector.split(", ").forEach(element => {
        elementSelectorArray.push(".website-fonts " + element)
      });
      const elementSelectorModified = elementSelectorArray.join(", ");
      const iframe = document.querySelector('#previewIframe');

      let iframeContents = (iframe.contentWindow || iframe.contentDocument);
      if (iframeContents.document) iframeContents = iframeContents.document;

      const elements = iframeContents.querySelectorAll(elementSelectorModified);
      
      elements.forEach((element) => {
        if (name === "overlay-menu"){
          if (this.fieldTarget.classList.contains('lp-closed')) {
            iframeContents.documentElement.wholePage.removeDisableScroll();
            element.classList.remove('mobile-menu-active');
            setTimeout(() => {
              element.querySelectorAll('.lp-header-navigation')[0].classList.remove('move-up');
            }, 200);
            setTimeout(() => {
              element.querySelectorAll('.lp-header-social-links')[0].classList.remove('move-up');
            }, 400);
          } else if (this.fieldTarget.classList.contains('lp-open')) {
            iframeContents.documentElement.wholePage.addDisableScroll();
            element.classList.add('mobile-menu-active');
            setTimeout(() => {
              element.querySelectorAll('.lp-header-navigation')[0].classList.add('move-up');
            }, 200);
            setTimeout(() => {
              element.querySelectorAll('.lp-header-social-links')[0].classList.add('move-up');
            }, 400);
          }
        }
      }); 
    }
   
  }

  animate(){
    const name = this.fieldTarget.dataset.name;
    const type = this.fieldTarget.dataset.type;
    const value = this.fieldTarget.value;
    const oldvalue = this.fieldTarget.dataset.oldValue;;
    const formButtons = document.getElementById('form-buttons-container');
    const hasChanged = value !== oldvalue;
    const form = this.fieldTarget.closest('form');

    // Add modified class to field
    if (hasChanged){
      this.fieldTarget.classList.add("modified-input");
    } else {
      this.fieldTarget.classList.remove("modified-input");
    }
    // Query all fields with modified class
    const modifiedInputs = form.querySelectorAll('.modified-input');
    
    if (modifiedInputs.length){
      form.classList.add("has-changed");
      formButtons.clickAppear.showHiddenElement();
    } else {
      form.classList.remove("has-changed");
      formButtons.clickAppear.hideHiddenElement();
    }

    if ("elementSelector" in this.fieldTarget.dataset){
      const elementSelector = this.fieldTarget.dataset.elementSelector;
      let elementSelectorArray = []
      
      // Prefix selectors
      elementSelector.split(", ").forEach(selector => {
        elementSelectorArray.push(".website-body " + selector)
      });
      
      const elementSelectorModified = elementSelectorArray.join(", ");
      const iframe = document.querySelector('#previewIframe');

      let iframeContents = (iframe.contentWindow || iframe.contentDocument);
      if (iframeContents.document) iframeContents = iframeContents.document;
      
      const styles = iframeContents.querySelector('#lp-styles-data');
      const elements = iframeContents.querySelectorAll(elementSelectorModified);      
      elements.forEach((element) => {


        if(type == "select"){
          const options = this.fieldTarget.options;
          const optionValues = [];
          for (let i = 0; i < options.length; i++) { 
            optionValues.push(options[i].value);
          }
          if (name === "weight"){
            element.style.fontWeight = value;
          } else if (name === "font-style"){
            element.style.fontStyle = value;
          } else if (name === "text-transform"){
            element.style.textTransform = value;
          } else if (name === "header-style-type"){
            element.classList.remove("color-theme-lightest", "color-theme-light", "color-theme-dark", "color-theme-darkest");
            if (value === "lp-style-theme"){
              const theme = element.dataset.colorTheme;
              const themeClassName = `color-theme-${theme}`;

              element.classList.add(themeClassName);

              this.themeStyles(theme, styles, element);
            } else {
              element.style.backgroundColor = null
            }
            element.classList.remove(...optionValues);
            element.classList.add(value);
          } else if (name === "menu-icon-animation"){
            element.classList.remove(...optionValues)
            element.classList.add(value);

            element.classList.add("is-active");
            setTimeout(() => {
              element.classList.remove("is-active");
            }, 800)
          } else if (name === "style"){
            const id = this.fieldTarget.dataset.id;
            const customFieldContainer = document.querySelector(`#custom-${id}`);
            const customFields = customFieldContainer.querySelectorAll(".lp-field");

            // Remove style classes and add .custom
            element.classList.remove("heading-1", "heading-2", "heading-3", "heading-4", "paragraph-1", "paragraph-2", "paragraph-3", "custom")

            if (value === "custom"){

              // Add class
              element.classList.add("custom")

              // Show custom styling
              customFields.forEach(field => {
                const fieldValue = field.value;
                const fieldType = field.dataset.type;
                const fieldName = field.dataset.name;

                if (fieldName == "family"){
                  const container = field.closest('.lp-font-picker');

                  container.fontSelect.selectIcon(fieldValue);
                  container.fontSelect.update(fieldValue, fieldValue);
                } else if (fieldName == "weight"){
                  element.style.fontWeight = fieldValue;
                } else if (fieldName == "font-style"){
                  element.style.fontStyle = fieldValue;
                } else if (fieldName == "text-transform"){
                  element.style.textTransform = fieldValue;
                } 
                if (fieldType == "range"){
                  const container = field.closest('.lp-range-container');

                  // Reset slide animation
                  container.rangeSlider.slide();

                  if (fieldName == "line-height"){
                    element.style.lineHeight = `${fieldValue}em`;
                  } else if (fieldName == "letter-spacing"){
                    element.style.letterSpacing = `${fieldValue}em`;
                  } else if (["site-navigation-size", "site-title-size"].includes(fieldName)){
                    element.style.fontSize = `${fieldValue}rem`;
                  }
                }
              })
            } else {

              // Add style class
              element.classList.add(element.dataset.size)

              customFields.forEach(field => {
                const fieldOldValue = field.dataset.oldValue;
                const fieldType = field.dataset.type;
                const fieldName = field.dataset.name;

                // Reset custom fields value
                field.value = fieldOldValue;

                // Remove custom styling from elements
                if (fieldName == "family"){
                  element.style.fontFamily = null;
                } else if (fieldName == "weight"){
                  element.style.fontWeight = null;
                } else if (fieldName == "font-style"){
                  element.style.fontStyle = null;
                } else if (fieldName == "text-transform"){
                  element.style.textTransform = null;
                } 
                if (fieldType == "range"){
                  if (fieldName == "line-height"){
                    element.style.lineHeight = null;
                  } else if (fieldName == "letter-spacing"){
                    element.style.letterSpacing = null;
                  } else if (["site-navigation-size", "site-title-size"].includes(fieldName)){
                    if (element.classList.contains('custom-size')){
                      const customSize = element.dataset.customSize

                      element.style.fontSize = customSize;
                    } else {
                      element.style.fontSize = null;
                    }
                  }
                }
              })
            }
          } else if (name === "size") {
            element.classList.remove(...optionValues)
            element.classList.add(value);

            if (value === "custom-size"){
              const id = this.fieldTarget.dataset.id;
              const customFieldContainer = document.querySelector(`#custom-size-${id}`);
              const customFields = customFieldContainer.querySelectorAll(".lp-field");

              // Show custom styling
              customFields.forEach(field => {
                const fieldValue = field.value;
                const fieldName = field.dataset.name;

                if (["custom-size"].includes(fieldName)){
                  element.style.fontSize = `${fieldValue}rem`;
                }
              })
            } else {
              element.style.fontSize = null
            }
          } else {
            element.classList.remove(...optionValues)
            element.classList.add(value);
          }
        } else if(type == "range"){
          
          if (name === "logo-height"){
            //const imageContainer = document.querySelector('#website-logo-image-container');
            //imageContainer.dataset.height = value;
            element.style.height = value + "px";
          } else if (name === "padding"){
            element.style.padding = `${value * 0.6}rem ${value}rem`;
          } else if (name === "line-height"){
            element.style.lineHeight = `${value}em`;
          } else if (name === "letter-spacing"){
            element.style.letterSpacing = `${value}em`;
            if (element.classList.contains('btn')) {
              const buttonText = element.querySelectorAll('.lp-btn-text')
              if (buttonText.length){
                buttonText[0].style.paddingLeft = `${value}em`;
              }
            }
          } else if (["heading-1-size", "heading-2-size", "heading-3-size", "heading-4-size", "paragraph-1-size", "paragraph-2-size", "paragraph-3-size", "base-size", "site-navigation-size", "site-title-size", "custom-size", "small-size", "large-size", "huge-size"].includes(name)){            
            element.style.fontSize = `${value}rem`;
            if (["custom-size"].includes(name)){
              element.setAttribute('data-custom-size', `${value}rem`);
            }
          } else if (["large-button-size", "medium-button-size", "small-button-size"].includes(name)){
            element.style.fontSize = `${value}em`;
          } else if (["social-icon-size"].includes(name)){
            element.style.fontSize = `${value}px`;
          } else if (["opacity"].includes(name)){
            element.style.opacity = `${value / 100}`;
          } else if (["border-thickness-custom"].includes(name)){
            element.style.borderWidth = value + "px";
          } else if (["shadow-spread", "shadow-distance", "shadow-blur"].includes(name)){
            const parentId = this.fieldTarget.dataset.parentId
            const parent = document.querySelector('#field-' + parentId);
            const spreadId = parent.dataset.spreadId
            const distanceId = parent.dataset.distanceId
            const blurId = parent.dataset.blurId
            const colorId = parent.dataset.colorId
            const spread = document.querySelector('#field-' + spreadId).value;
            const distance = document.querySelector('#field-' + distanceId).value;
            const blur = document.querySelector('#field-' + blurId).value;
            const color = document.querySelector('#field-' + colorId).value;
            const rgba = this.hexToRgbA(color);
            
            element.style.boxShadow = `0 ${distance}px ${blur}px ${spread}px ${rgba}`;
          } else if (["link-spacing"].includes(name)){
            if (elementSelector == ".lp-header"){
              const layout = element.dataset.layout
              const navigation = element.querySelectorAll('.lp-header-navigation')[0];
              const navigationItems = navigation.querySelectorAll('li');

              if(["layout-1", "layout-2"].includes(layout)){
                navigationItems.forEach(item => item.style.marginLeft = `${value}vw`);
              } else if(["layout-4"].includes(layout)){
                navigationItems.forEach(item => item.style.marginRight = `${value}vw`);
              } else if(["layout-3", "layout-5"].includes(layout)){
                navigationItems.forEach(item => {
                  item.style.marginLeft = (value / 2) + "vw"
                  item.style.marginRight = (value / 2) + "vw"
                });
              }
            }
          } else if (["site-margin"].includes(name)){
            element.style.paddingRight = `${value}vw`;
            element.style.paddingLeft = `${value}vw`;
          } else if (["element-spacing"].includes(name)){
            if (elementSelector == ".lp-header-element"){
              element.style.marginLeft = `${value}vw`;
            }
          } else if (["vertical-padding"].includes(name)){
            element.style.paddingTop = `${value}vw`;
            element.style.paddingBottom = `${value}vw`;
            if (elementSelector == ".lp-header-contents-inner"){
              document.querySelector('#header-height').headerHeight.connect()
            }
          } else if (["mobile-link-spacing"].includes(name)){
            element.style.marginTop = `${value}vw`;
            element.style.marginBottom = `${value}vw`;
          }
        } else if(type == "switch"){
          if (["border-solid", "border-dynamic"].includes(name)){
            element.classList.toggle('border-none');
          } else if (["drop-shadow"].includes(name)){
            element.classList.toggle(`${name}-none`);
          } else if (["fixed-position"].includes(name)){
            element.classList.toggle(name);
          } else {
            element.classList.toggle('hidden');
          }
        } else if(type == "font-picker"){
          element.style.fontFamily = this.fieldTarget.dataset.value;
        } else if(type == "color"){
          const theme = this.fieldTarget.dataset.theme;

          if (["section-background"].includes(name)){
            element.style.backgroundColor = value;
            this.themeStyles(theme, styles, element, "section-background", "set", value)
          } else if (["background-color"].includes(name)){
            element.style.backgroundColor = value;
          } else if (["border-color"].includes(name)){
            element.style.borderColor = value;
          } else if (["shadow-color"].includes(name)){
            const parentId = this.fieldTarget.dataset.parentId
            const parent = document.querySelector('#field-' + parentId);
            const spreadId = parent.dataset.spreadId
            const distanceId = parent.dataset.distanceId
            const blurId = parent.dataset.blurId
            const spread = document.querySelector('#field-' + spreadId).value;
            const distance = document.querySelector('#field-' + distanceId).value;
            const blur = document.querySelector('#field-' + blurId).value;
            const rgba = this.hexToRgbA(value);

            element.style.boxShadow = `0 ${distance}px ${blur}px ${spread}px ${rgba}`;
          } else {
            element.style.color = value;
          }
        } else {
          if (["twitter", "facebook", "instagram", "github"].includes(name)){
            if (value !== ""){
              element.classList.remove("hidden")
            } else {
              element.classList.add("hidden")
            }
          } else {
            element.innerHTML = value;
          }
        }
      })
    }
  }

  animateMultiple(){
    const name = this.element.dataset.name;
    const type = this.element.dataset.type;
    const value = this.element.value;
    const oldvalue = this.element.dataset.oldValue;;
    const formButtons = document.getElementById('form-buttons-container');
    const hasChanged = value !== oldvalue;
    const form = this.element.closest('form');

    // Add modified class to field
    if (hasChanged){
      this.element.classList.add("modified-input");
    } else {
      this.element.classList.remove("modified-input");
    }
    // Query all fields with modified class
    const modifiedInputs = form.querySelectorAll('.modified-input');
    
    if (modifiedInputs.length){
      form.classList.add("has-changed");
      formButtons.clickAppear.showHiddenElement();
    } else {
      form.classList.remove("has-changed");
      formButtons.clickAppear.hideHiddenElement();
    }

    const customId = "field-" + this.element.dataset.customId
    const customValueId = "value-" + this.element.dataset.customId
    const customField = document.getElementById(customId);
    const customValue = document.getElementById(customValueId);
    
    if (customField && value !== "custom"){
      customField.value = value
      customValue.innerHTML = value

      // Resolve range background
      const min = customField.min
      const max = customField.max
      const val = customField.value
      customField.style.backgroundSize = (val - min) * 100 / (max - min) + '% 100%'
    }

    if ("elementSelector" in this.element.dataset){
      const elementSelector = this.element.dataset.elementSelector;
      let elementSelectorArray = []
      
      // Prefix selectors
      elementSelector.split(", ").forEach(element => {
        elementSelectorArray.push(".website-fonts " + element)
      });
      const elementSelectorModified = elementSelectorArray.join(", ");

      const iframe = document.querySelector('#previewIframe');

      let iframeContents = (iframe.contentWindow || iframe.contentDocument);
      if (iframeContents.document) iframeContents = iframeContents.document;

      const styles = iframeContents.querySelector('#lp-styles-data');
      const elements = iframeContents.querySelectorAll(elementSelectorModified);

      elements.forEach((element) => {
        if(type == "radio"){
          if (["border-thickness"].includes(name)){
            element.style.borderWidth = value + "px";
          } else if (["border-position"].includes(name)){
            element.classList.remove("lp-border", "lp-border-h", "lp-border-t", "lp-border-b");
            element.classList.add(value);
          } else if(["mobile-overlay-alignment"].includes(name)){
            element.classList.remove("mobile-overlay-alignment-left", "mobile-overlay-alignment-center", "mobile-overlay-alignment-right");
            element.classList.add(value);
          }
        } else if(type == "color-theme-picker"){
          const themeClass = name === "mobile-color-theme" ? `mobile-color-theme-${value}` : `color-theme-${value}`;

          if (name === "mobile-color-theme"){
            element.classList.remove("mobile-color-theme-lightest", "mobile-color-theme-light", "mobile-color-theme-dark", "mobile-color-theme-darkest");
          } else {
            element.classList.remove("color-theme-lightest", "color-theme-light", "color-theme-dark", "color-theme-darkest");
          }
          element.classList.add(themeClass)
          element.dataset.colorTheme = themeClass
          const theme = value;
          this.themeStyles(theme, styles, element);
          
        } else if(["header-layout", "header-layout-mobile"].includes(type)){
          if (name === "mobile-layout"){
            element.classList.remove("mobile-layout-1", "mobile-layout-2", "mobile-layout-3", "mobile-layout-4", "mobile-layout-5");
          } else {
            element.classList.remove("layout-1", "layout-2", "layout-3", "layout-4", "layout-5");
          }
          element.classList.add(value);
        }
      })
    }
  }

  themeStyles(theme, styles, element, attribute = "all", type="get", value = null){

    if (["all", "section-background"].includes(attribute)){
      if (type === "set"){
        styles.setAttribute(`data-${theme}-color-sitewide-section-background`, value);
      } else {
        element.style.backgroundColor = styles.getAttribute(`data-${theme}-color-sitewide-section-background`);
      }

    } else if (["all", "headers"].includes(attribute)){
      element.querySelectorAll('h1, h2, h3, h4, .heading-1, .heading-2, .heading-3, .heading-4').forEach(element => {
        element.style.color = styles.getAttribute(`data-${theme}-color-header`);
      });
    } else if (["all", "paragraphs"].includes(attribute)){
      element.querySelectorAll('p, .paragraph-1, .paragraph-2, .paragraph-3').forEach(element => {
        element.style.color = styles.getAttribute(`data-${theme}-color-paragraph`);
      });
    } else if (["all", "meta"].includes(attribute)){
      element.querySelectorAll('time, .miscellaneous-style').forEach(element => {
        element.style.color = styles.getAttribute(`data-${theme}-color-meta`);
      });
    } else if (["all", "buttons"].includes(attribute)){
      element.querySelectorAll('a:not(.not-color-links):not(.btn):not(.ql-preview):not(.ql-action):not(.ql-remove), .lp-color-links').forEach(element => {
        element.style.color = styles.getAttribute(`data-${theme}-color-links`);
      });
    } else if (["all", "button-text"].includes(attribute)){
      element.querySelectorAll('a.btn').forEach(element => {
        element.style.color = styles.getAttribute(`data-${theme}-color-button-text`);
      });
    } else if (["all", "button-background"].includes(attribute)){
      element.querySelectorAll('a.btn').forEach(element => {
        element.style.backgroundColor = styles.getAttribute(`data-${theme}-color-button-background`);
      });
    }
  }

  onlyNumbers(){
    const field = this.fieldTarget
    const hiddenField = this.hiddenTarget
    const value = field.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
    
    field.value = hiddenField.value = value;
  }

  formatNumbers(){
    const field = this.fieldTarget
    
    if(field.value === ""){
      field.disabled = true;
      field.value = field.dataset.value = parseFloat(0).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2});
    } else {
      field.disabled = false;
      field.value = field.dataset.value = parseFloat(field.value).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2});
    }
  }

  populateSubdomain(){
    this.connectedFieldTarget.value = this.fieldTarget.value.replace(/[^a-zA-Z0-9 ]/g, "").replace(/\s+/g, '-').toLowerCase().substring(0, 63);
  }

  prependText(){
    const originalValue = this.connectedFieldTarget.dataset.originalValue
    this.connectedFieldTarget.value = this.fieldTarget.value + originalValue;
  }

  loadURL(){
    const url = this.element.dataset.url;
    const search = this.element.value;
    const name = this.element.dataset.name;
    const theming = this.element.dataset.theming;
    const template = this.element.dataset.template;
    const section = this.element.dataset.section;
    const block = this.element.dataset.block;
    const setting = this.element.dataset.setting;
    const menu = this.element.dataset.menu;
    const menuItem = this.element.dataset.menuItem;
    const params = {};

    params.search = search;
    if ("name" in this.element.dataset) {
      params.name = name;
    }
    if ("theming" in this.element.dataset) {
      params.theming = theming;
    }
    if ("template" in this.element.dataset) {
      params.template = template;
    }
    if ("section" in this.element.dataset) {
      params.section = section;
    }
    if ("block" in this.element.dataset) {
      params.block = block;
    }
    if ("setting" in this.element.dataset) {
      params.setting = setting;
    }
    if ("menu" in this.element.dataset) {
      params.menu = menu;
    }
    if ("menuItem" in this.element.dataset) {
      params["menu_item"] = menuItem;
    }

    fetch(url + "?" + new URLSearchParams(params), {
      method: 'POST',
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.text())
    .then(html => Turbo.renderStreamMessage(html))
    .then(data => {
      
    });
  }
}
