import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import { useClickOutside } from 'stimulus-use'
let debounce = require('lodash/debounce');

export default class extends Controller {
  static targets = [ "buttonLoader", "buttonText", "button", "hidden", "radios", "disabledField" ]

  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this
    useClickOutside(this);

    this.submitDebounce = debounce(this.submitDebounce, 1000).bind(this)
  }

  amimate(event) {
    event.preventDefault();

    // Show loader
    this.buttonLoaderTarget.style.opacity = 1
    this.buttonTextTarget.style.opacity = 0
  }

  deleteLink(event){

    const url = this.buttonTarget.dataset.key;
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    const formatting = this.buttonTarget.dataset.formatting;
    const headers = formatting === "html" ? { 'X-CSRF-Token': csrf } : {
      Accept: "text/vnd.turbo-stream.html",
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf
    }

    this.amimate(event);

    fetch(url, {
      method: 'DELETE',
      headers: headers,
    })
    .then((response) => response.text())
    .then(html => Turbo.renderStreamMessage(html))
    .then(data => {
    });
  }



  loadAction() {
    this.buttonTarget.disabled = true
    this.buttonTarget.classList.add("submitting");

    // Show loader
    this.buttonLoaderTarget.style.opacity = 1
    this.buttonTextTarget.style.opacity = 0
  }

  unloadAction() {
    this.buttonTarget.disabled = false
    this.buttonTarget.classList.remove("submitting");

    // Show loader
    this.buttonLoaderTarget.style.opacity = 0
    this.buttonTextTarget.style.opacity = 1
  }

  loadURL(event){
    event.preventDefault();

    const form = this.buttonTarget.closest('form');
    const url = form.action;
    const preview = document.querySelector('#preview');
    const frameContainer = document.querySelector('#previewIframeContainer');
    const pageURL = frameContainer.dataset.pageUrl;
    const iframe = document.querySelector('#previewIframe');
    const formButtons = document.getElementById('form-buttons-container');
    const forms = document.querySelectorAll('form');

    const pageID = frameContainer.dataset.pageId
    const postID = frameContainer.dataset.postId
    const params = { page_id: pageID }

    this.loadAction();
    
    if (postID){
      params.post_id = postID;
    }

    fetch(url + "?" + new URLSearchParams(params), {
      method: 'PATCH',
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        'Content-Type': 'application/json',
        
      },
    })
    .then((response) => response.text())
    .then(html => Turbo.renderStreamMessage(html))
    .then(data => {
      let iframeContents = (iframe.contentWindow || iframe.contentDocument);
      if (iframeContents.document) iframeContents = iframeContents.document;
      const formPreview = iframeContents.getElementById("formPreview");

      if (formPreview){
        formPreview.classList.remove("has-changed");
      }
      iframe.src = pageURL
      iframe.onload = function() { 
        forms.forEach(form => {
          form.classList.remove("has-changed");
        });
        preview.clickActive.uneditWithoutUrlChange();
        preview.clickAppear.closeSidePanel();

        document.body.layout.removeEditorView();
        formButtons.clickAppear.hideHiddenElement();
        
        // Unbind onload
        iframe.onload = null;
      }
    });
  }

  load(event) {
    event.preventDefault();

    this.loadAction();

    // Submit form.
    setTimeout(() => {
      this.submit()
    }, 200);
  }

  submit() {
    Rails.fire(this.element, 'submit');
  }

  enableSubmit() {
    this.disabledFieldTargets.forEach(field => {
      field.disabled = false;
    })
    this.loadAction();

    // Submit form.
    setTimeout(() => {
      this.submit()
    }, 200);
  }

  submitDebounce() {
    Rails.fire(this.element, 'submit');
  }

  saveAndOpen() {
    this.hiddenTarget.value = true
    Rails.fire(this.element, 'submit');
  }

  submitEdit(){
    const iframe = document.getElementById("previewIframe");
    var iframeContents = (iframe.contentWindow || iframe.contentDocument);
    if (iframeContents.document) iframeContents = iframeContents.document;
    const form = iframeContents.getElementById("formPreview");

    Rails.fire(form, 'submit');
  }

  loadSidePanel(){
    const editView = document.querySelector('#side');
    
    Rails.fire(this.element, 'submit');
    editView.sidePanel.loadWithText();
  }

  unsavedChangesWarning(evt){
    if (this.element.classList.contains('has-changed')){
      const unsaved_changes_warning = "Changes you made may not be saved.";
      evt.returnValue = unsaved_changes_warning;
      return unsaved_changes_warning;
    }    
  }

  previewFormChanged(){
    const formButtons = parent.document.getElementById('form-buttons-container');
    const formPreview = document.getElementById("formPreview");


    formButtons.clickAppear.showHiddenElement();
    formPreview.classList.add("has-changed");
  }

  toggleSubmitButtonRadio(){
    const radios = this.radiosTarget;
    const checked = radios.querySelectorAll('input[type="radio"]:checked');

    if(checked != null){
      this.buttonTarget.disabled = false;
    }
  }
}
