import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["element"]

  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this
  }

  // Remove element
  remove(event) {
    this.element.remove();
    if (this.hasElementTarget && this.elementTarget.hasAttribute("data-key")) {
      const elementID = this.elementTarget.dataset.key;
      const element = document.querySelector(`#${elementID}`);
      
      // Fade
      element.classList.add("opacity-0");

      // Hide
      setTimeout(() => {
        element.classList.add("hidden");
      }, 200);
    }
  }


  // Clears turbo frame
  clear() {
    this.element.removeAttribute('src');
    this.element.innerHTML = '';
  }

  // Fade out
  fadeOut() {

    // Fade
    this.element.classList.add("opacity-0");

    // Hide
    setTimeout(() => {
      this.element.classList.add("hidden");
    }, 200);
  }

  removeLogo(){
    const iframe = document.querySelector('#previewIframe');
    if (iframe !== null){
      let iframeContents = (iframe.contentWindow || iframe.contentDocument);
      if (iframeContents.document) iframeContents = iframeContents.document;
      const imageContainer = iframeContents.querySelector('#website-logo-image-container');
      const text = iframeContents.querySelector('#website-logo-text');
      const formButtons = parent.document.getElementById('form-buttons-container');

      imageContainer.innerHTML = "";
      imageContainer.classList.add('hidden');
      text.classList.remove('hidden');

      formButtons.clickAppear.showHiddenElement();
    }
  }

}
