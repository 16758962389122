import { Controller } from "stimulus"
let debounce = require('lodash/debounce');

export default class extends Controller {

  static targets = ["field"]

  connect() {

    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this
    this.search = debounce(this.format, 300).bind(this)
  }

  format(){
    let value = this.fieldTarget.value
    const dayId = this.fieldTarget.id
    const params = { value: value, day_id: dayId }
    const url = this.fieldTarget.dataset.url + "?" + new URLSearchParams(params);
    
    fetch(url, {
      method: 'POST',
    })
    .then(response => response.json())
    .then(json => {
      this.fieldTarget.value = json.times;   
    })
  }
}
