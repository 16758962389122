import { Controller } from 'stimulus'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ["element", "button", "icon", "replaceName", "replaceIcon"]

  connect() {
    useClickOutside(this)
  }

  clickOutside(event) {

    if (!this.element.classList.contains('lp-click-outside-disabled')) {
      // Fade
      if(this.hasButtonTarget){
        if(event.target != this.buttonTarget){
          if (this.hasElementTarget) {
            this.elementTarget.classList.add("opacity-0");
          }
          this.buttonTarget.classList.remove("lp-active");
        }
      } else {
        if (this.hasElementTarget) {
          this.elementTarget.classList.add("-translate-y-3");
          this.elementTarget.classList.add("opacity-0");
        }
      }
      if (this.hasElementTarget) {
        // Hide
        setTimeout(() => {
          this.elementTarget.classList.add("hidden");
        }, 300);
      }
      if (this.hasIconTarget) {
        this.iconTarget.classList.remove("li-angle-up");
        this.iconTarget.classList.add("li-angle-down");
      }
      if (this.hasReplaceIconTarget) {
        this.replaceIconTarget.value = this.replaceIconTarget.dataset.icon
      }
    }
  }
  
  disable(){
    this.element.classList.add("lp-click-outside-disabled")
  }

  enable(){
    this.element.classList.remove("lp-click-outside-disabled")
  }
}
