import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ["name", "field", "option", "button"]

  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this
  }

  select(event){
    event.preventDefault();

    const id = this.optionTarget.dataset.id;
    const parentComponent = document.querySelector('#' + id);
    const name = this.optionTarget.dataset.name;
    const value = this.optionTarget.dataset.value;

    // Display selection name and update field with selection value.
    parentComponent.fontSelect.update(name, value);
    
    // Hide check icons on all buttons.
    parentComponent.querySelectorAll(".button-container ").forEach(element => element.classList.add('hide-icon'));
    this.element.classList.remove('hide-icon');
  }

  selectIcon(value){
    this.element.querySelectorAll(".button-container ").forEach(element => {
      element.querySelectorAll("button").forEach(button => {
        if (button.dataset.name === value){
          element.classList.remove('hide-icon');
        } else {
          element.classList.add('hide-icon');
        }
      });
    });
    this.element.classList.remove('hide-icon');
  }

  removeIcon(){
    this.buttonTargets.forEach(element => element.classList.add('hide-icon'));
  }

  update(name, value){

    let elements;

    

    if ("elementSelector" in this.fieldTarget.dataset){
      const elementSelector = this.fieldTarget.dataset.elementSelector;
      let elementSelectorArray = []

      
      // Prefix selectors
      elementSelector.split(", ").forEach(element => {
        elementSelectorArray.push(".website-fonts " + element)

      });


      const elementSelectorModified = elementSelectorArray.join(", ");
      const iframe = document.querySelector('#previewIframe');

      let iframeContents = (iframe.contentWindow || iframe.contentDocument);
      if (iframeContents.document) iframeContents = iframeContents.document;

      const elements = iframeContents.querySelectorAll(elementSelectorModified);




      elements.forEach((element) => {
        element.style.fontFamily = value;
      });
    }

    // Display selection name.
    this.nameTarget.innerHTML = name;

    // Update field with selection value.
    this.fieldTarget.value = value;


  }
}
