import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["circle"]

  connect() {

    const circle = this.circleTarget;
    const radius = circle.r.baseVal.value;
    const circumference = radius * 2 * Math.PI;
    const progress = circle.dataset.progress;

    circle.style.strokeDasharray = `${circumference} ${circumference}`;
    circle.style.strokeDashoffset = `${circumference}`;
    circle.style.stroke = progress <= 25 ? "#EF4444" : progress > 25 && progress <= 50 ? "#F59E0B" : progress > 50 && progress <= 75 ? "#3B82F6" : "#10B981";

    function setProgress(percent) {
      const offset = circumference - percent / 100 * circumference;
      circle.style.strokeDashoffset = offset;
    }

    setProgress(progress);
  }
}
