import { Controller } from "stimulus";
import { scrollIntoView } from "seamless-scroll-polyfill";

export default class extends Controller {

  static targets = [ "page", "side"]

  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this

    scrollIntoView(this.element, { behavior: "smooth" });
  }
}
