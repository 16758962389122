import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "activitiesButton", "form" ]

  read(event) {
    Rails.fire(this.formTarget, 'submit');
  }
}
