import { Controller } from 'stimulus'

export default class extends Controller {

  connect() {
    var target = this.element;
    var observer = new MutationObserver(function(mutations) {
      mutations.forEach(function(mutation) {
          if (target.textContent == '') {
              target.innerHTML = '';
          }
      });    
    });
    var config = { attributes: true, childList: true, characterData: true };
    observer.observe(target, config);
  }
}
