import { Controller } from "stimulus";

export default class extends Controller {

    static targets = ["input", "value"]
  
    connect() {

        const value = this.inputTarget.value;
        this.updateLabel(value);
    }

    label(event){
        const value = event.target.value;
        this.updateLabel(value);
        
    }

    updateLabel(value){
        let hours = Math.floor(value / 60);
        let minutes = value - (hours * 60);
        let end_tag;

        if(minutes == 0) {
            minutes = '0' + minutes;
        }
        if(hours < 12){
            end_tag = "AM";
        } else {
            end_tag = "PM";
        }
        this.valueTarget.innerHTML = hours + ":" + minutes + end_tag;
    }
}