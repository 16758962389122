import { Controller } from "stimulus"
let debounce = require('lodash/debounce');

export default class extends Controller {

  connect() {
    const html = this.element.innerHTML;
    const referenceId = this.element.dataset.referenceId;
    const type = this.element.dataset.type;
    const iframe = document.querySelector('#lp-editor-frame');
    let iframeContents = (iframe.contentWindow || iframe.contentDocument);
    if (iframeContents.document) iframeContents = iframeContents.document;
    const reference = iframeContents.querySelector(referenceId); 


    reference.insertAdjacentHTML(
      type,
      html,
    );
    this.element.remove();
  }
}
