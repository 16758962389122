import { Controller } from "stimulus"
let debounce = require('lodash/debounce');

export default class extends Controller {

  static targets = ["field"]

  connect() {

    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this
    this.search = debounce(this.search, 300).bind(this)
  }

  search(){
    const parentId = "parentId" in this.fieldTarget.dataset ? this.fieldTarget.dataset.parentId : null
    const objectOneId = "objectOneId" in this.fieldTarget.dataset ? this.fieldTarget.dataset.objectOneId : null
    const objectTwoId = "objectTwoId" in this.fieldTarget.dataset ? this.fieldTarget.dataset.objectTwoId : null
    const params = { search: this.fieldTarget.value, parent_class: this.fieldTarget.dataset.parentClass, tenant_id: this.fieldTarget.dataset.tenantId, business_id: this.fieldTarget.dataset.businessId }
    if (parentId){
      params.parent_id = parentId;
    }
    if (objectOneId){
      params.object_one_id = objectOneId;
    }
    if (objectTwoId){
      params.object_two_id = objectTwoId;
    }
    const url = this.fieldTarget.dataset.url + "?" + new URLSearchParams(params);
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        'Content-Type': 'application/json',
        
      },
    })
    .then((response) => response.text())
    .then(html => Turbo.renderStreamMessage(html))
    .then(data => {
      //document.querySelector('#preview').clickActive.edit();
    });
  }
}
