import { Controller } from "stimulus";

export default class extends Controller {

  static targets = [ "input", "value", "value1", "value1Old", "link", "limit" ]

  connect() {

    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this

    const data = "data" in this.element.dataset ? JSON.parse(this.element.dataset.data) : null;
    const interval = this.element.dataset.interval
    const index = this.inputTarget.value;
    const min = this.inputTarget.min;
    const max = this.inputTarget.max;
    const val = this.inputTarget.value;

    this.updateInfo(data, interval, index, min, max, val);
  }

  slide(){
    const data = "data" in this.element.dataset ? JSON.parse(this.element.dataset.data) : null;
    const interval = this.element.dataset.interval
    const index = this.inputTarget.value
    const min = this.inputTarget.min;
    const max = this.inputTarget.max;
    const val = this.inputTarget.value;
    
    this.updateInfo(data, interval, index, min, max, val);
  }

  updateURLParameter(url, param, paramVal){
      var TheAnchor = null;
      var newAdditionalURL = "";
      var tempArray = url.split("?");
      var baseURL = tempArray[0];
      var additionalURL = tempArray[1];
      var temp = "";

      if (additionalURL) 
      {
          var tmpAnchor = additionalURL.split("#");
          var TheParams = tmpAnchor[0];
              TheAnchor = tmpAnchor[1];
          if(TheAnchor)
              additionalURL = TheParams;

          tempArray = additionalURL.split("&");

          for (var i=0; i<tempArray.length; i++)
          {
              if(tempArray[i].split('=')[0] != param)
              {
                  newAdditionalURL += temp + tempArray[i];
                  temp = "&";
              }
          }        
      }
      else
      {
          var tmpAnchor = baseURL.split("#");
          var TheParams = tmpAnchor[0];
              TheAnchor  = tmpAnchor[1];

          if(TheParams)
              baseURL = TheParams;
      }

      if(TheAnchor)
          paramVal += "#" + TheAnchor;

      var rows_txt = temp + "" + param + "=" + paramVal;
      return baseURL + "?" + newAdditionalURL + rows_txt;
  }

  updateInfo(data, interval, index, min, max, val){
    if (("data" in this.element.dataset && data[interval]["tiers"][index])) {
      const value1 = Math.round((data[interval]["tiers"][index].flat_amount / 100)).toLocaleString();
      const value1Old = (data["monthly"]["tiers"][index].flat_amount / 100).toLocaleString();
      const value2 = data[interval]["tiers"][index].up_to === "inf" ? "500,000" : data[interval]["tiers"][index].up_to.toLocaleString();
      const url = this.element.dataset.url;
      const slug = data[interval]["tiers"][index].up_to
      const plansIndex = data["plans"].findIndex(plan => plan["slug"] === slug)
      const planId = data["plans"][plansIndex].id
      const urlUpdated = this.updateURLParameter(url, "plan_id", planId);

      if(value1 === "0" || interval === "monthly"){
        this.value1OldTarget.classList.add("hidden");
      } else {
        this.value1OldTarget.classList.remove("hidden");
      }
      this.value1Target.innerHTML = value1;
      this.value1OldTarget.innerHTML = `$${value1Old}`;
      this.linkTarget.href = urlUpdated

      this.limitTargets.forEach(limit => {
        const limitName = limit.dataset.name;
        const emailsIndex = data["emails"].findIndex(email => email["slug"] === slug)
        const emailsValue = data["emails"][emailsIndex].emails

        console.log("emailsValue", emailsValue);
        console.log("value2", value2);
        console.log("limitName", limitName);
        console.log("limit", limit);
        console.log("emails", limitName === "emails");
        console.log("subscribers", limitName === "subscribers");

        if (limitName === "emails-m"){
          limit.innerHTML = emailsValue.toLocaleString();
        } else if (limitName === "subscribers") {
          limit.innerHTML = value2;
        }
      });
    }

    if (this.hasValueTarget){
      this.valueTarget.innerHTML = this.inputTarget.value;
    }

    this.inputTarget.style.backgroundSize = (val - min) * 100 / (max - min) + '% 100%';
  }
}
