import { Controller } from "stimulus"
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import 'intl-tel-input/build/js/utils';

export default class extends Controller {

  static targets =  [ "input", "output", "hidden", "icon" ]

  connect() {
    const input = this.inputTarget;
    const initialCountry = this.inputTarget.dataset.country;

    intlTelInput(input, {
      initialCountry: initialCountry,
    });
  }

  handleChange(){
    const input = this.inputTarget;
    const output = this.outputTarget;
    const hidden = this.hiddenTarget;
    const icon = this.iconTarget;
    const iti = window.intlTelInputGlobals.getInstance(input);

    if (iti.isValidNumber()){
      hidden.value = iti.getNumber();
      icon.classList.remove("hidden");
      output.innerHTML = ""
    } else {
      hidden.value = null;
      icon.classList.add("hidden");
      output.innerHTML = "Enter a valid number."
    }
  }
}
