import { Controller } from "stimulus"

export default class extends Controller {

  connect() {

    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this

    const url = this.element.dataset.imageUrl
    this.element.closest("#lp-editor").editor.imageSelect(url);
  }
}
