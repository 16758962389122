import { Controller } from 'stimulus'
import 'waypoints/lib/noframework.waypoints.min.js';

export default class extends Controller {

  connect() {
      const animatedElement = this.element;
      const animation = animatedElement.dataset.animation;
      const delay = animatedElement.dataset.delay;
      const waypointIgnored = new Waypoint({
        element: animatedElement,
        handler: function() {
          if ( $(window).width() > 767) {
            setTimeout(() => {
              animatedElement.classList.add(animation);
            }, delay);
          } else {
            animatedElement.classList.add('fade-in');
          }
          this.destroy();
        },
        offset: '100%'
      });
  }
}
