import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    if(this.element.dataset.update === "true"){


      if(this.element.classList.contains('swiper-slide')){


      
        const swiperElement = this.element.closest(".swiper");
        const swiper = swiperElement.swiper;

        swiper.update();
      }
    }
  }
}
