import { Controller } from "stimulus"
import Swiper from 'swiper';
import { Navigation, Autoplay, EffectFade } from 'swiper/modules';
// import Swiper and modules styles
// import styles bundle
import 'swiper/modules/effect-fade';

export default class extends Controller {

  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this

    // init Swiper:
    const slidesPerView = this.element.dataset.slidesPerView;
    const spaceBetween = this.element.dataset.spaceBetween;
    const loop = this.element.dataset.loop;
    const autoPlay = this.element.dataset.autoPlay;
    const autoPlayDelay = parseInt(this.element.dataset.autoPlayDelay);
    const effect = this.element.dataset.effect;

    const swiper = new Swiper(this.element, {
      threshold: 10,
      slidesPerView: slidesPerView,
      spaceBetween: spaceBetween,
      loop: loop,
      modules: [Navigation, Autoplay, EffectFade],
      navigation: {
        nextEl: '.lp-next',
        prevEl: '.lp-prev',
      },
      autoplay: autoPlay === "true" ? {
        delay: autoPlayDelay,
        pauseOnMouseEnter: true,
      } : false,
      effect: effect,
      fadeEffect: { crossFade: true },
    });
  }

}
