import { Controller } from "stimulus"
let debounce = require('lodash/debounce');

export default class extends Controller {

  static targets = ["interval", "amount", "billed", "old", "priceField", "summaryLoad"]

  connect() {

    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this
  }
  
  updateDetails(event){
    const value = event.target.value;

    if (value == "yearly"){

      // Update amounts
      this.amountTargets.forEach(amount => {
        amount.textContent = amount.dataset.year
      });

      // Update intervals
      this.intervalTargets.forEach(interval => {
        interval.textContent = interval.dataset.year
      });

      // Update billed
      this.billedTargets.forEach(billed => {
        billed.textContent = billed.dataset.year
      });

      // Show old prices
      this.oldTargets.forEach(old => {
        old.classList.remove("hidden");
        setTimeout(() => {
          old.classList.remove("opacity-0");
        });
      });

      // Update price field
      if (this.hasPriceFieldTarget) {
        this.priceFieldTarget.value = this.priceFieldTarget.dataset.year
      }
    } else {

      // Update amounts
      this.amountTargets.forEach(amount => {
        amount.textContent = amount.dataset.month
      });
      
      // Update intervals
      this.intervalTargets.forEach(interval => {
        interval.textContent = interval.dataset.month
      });

      // Update billed
      this.billedTargets.forEach(billed => {
        billed.textContent = billed.dataset.month
      });

      // Hide old prices
      this.oldTargets.forEach(old => {
        old.classList.add("opacity-0");
        setTimeout(() => {
          old.classList.add("hidden");
        }, 200);
      });

      // Update price field
      if (this.hasPriceFieldTarget) {
        this.priceFieldTarget.value = this.priceFieldTarget.dataset.month
      }
    }
  }

  updateInterval(event){

    const selection = event.target;
    const url = selection.dataset.url
    
    // Summary loader
    this.summaryLoad();

    // Fetch updated summary
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        'Content-Type': 'application/json',
        
      },
    })
    .then((response) => response.text())
    .then(html => Turbo.renderStreamMessage(html))
  }

  summaryLoad(){

    // Load summary
    this.summaryLoadTarget.classList.remove("hidden");
    setTimeout(() => {
      this.summaryLoadTarget.classList.remove("opacity-0");
    });
  }

  summaryLoadRemove(){
    
    // Remove load from summary
    this.summaryLoadTarget.classList.add("opacity-0");
    setTimeout(() => {
      this.summaryLoadTarget.classList.add("hidden");
    }, 200);
  }

  updateData(event){
    const value = event.target.value;
    const sliders = this.element.querySelectorAll('.lp-range-container');

    sliders.forEach(slider => {
      slider.dataset.interval = value;
      slider.rangeSlider.slide();
    });
  }


}
