import { Controller } from "stimulus"
let debounce = require('lodash/debounce');

export default class extends Controller {

  connect() {
    const html = this.element.innerHTML;
    const orginalId = this.element.dataset.originalId;
    const iframe = document.querySelector('#lp-editor-frame');
    

    let iframeContents = (iframe.contentWindow || iframe.contentDocument);
    if (iframeContents.document) iframeContents = iframeContents.document;
    
    const original = iframeContents.querySelector(orginalId); 
    
    original.insertAdjacentHTML(
      "afterend",
      html,
    );
    
    this.element.remove();
  }
}
