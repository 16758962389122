import { Controller } from 'stimulus'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {

  static targets = ["button", "options"]


  connect() {
    useClickOutside(this)
  }

  clickOutside(event) {
    this.hideOptions();

    if(!event.target.closest('.lp-option-box')){
      document.querySelectorAll('.lp-block').forEach(block => {
        block.classList.remove('pointer-events-none')
      });
    }
  }

  hideOptions(){
    this.optionsTarget.classList.add("-translate-y-3", "opacity-0");
    setTimeout(() => {
      this.optionsTarget.classList.add("hidden");
    }, 150);
  }
}
