import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "body" ]

  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this

    // Set dark mode
    const appearanceSetting = document.documentElement.dataset.appearance
    if (appearanceSetting == 'dark' || (appearanceSetting == 'device' && window.matchMedia('(prefers-color-scheme: dark)').matches) || (appearanceSetting == '' && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
        const newColorScheme = e.matches ? "dark" : "light";
        const appearanceSetting = document.documentElement.dataset.appearance

        if (newColorScheme == "dark" && appearanceSetting == '' || newColorScheme == "dark" && appearanceSetting == 'device' || appearanceSetting == 'dark') {
          document.documentElement.classList.add('dark')
        } else {
          document.documentElement.classList.remove('dark')
        }
    }); 
  }

  changeAppearance(event){
    const value = event.target.value;
    
    // Update dataset
    document.documentElement.dataset.appearance = value
   
    if (value === 'dark' || (value == "device" && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }

  addDisableScroll(){
    this.element.classList.add("stop-scroll");
    this.bodyTarget.classList.add("stop-scroll");
  }

  removeDisableScroll(){
    this.element.classList.remove("stop-scroll");
    this.bodyTarget.classList.remove("stop-scroll");
  }

  toggleDisableScroll(){
    this.element.classList.toggle("stop-scroll");
    this.bodyTarget.classList.toggle("stop-scroll");
  }
}
