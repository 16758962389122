import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    document.addEventListener('turbo:frame-render', async e => {
      history.pushState(history.state, '', this.element.src)
    })
    window.addEventListener('popstate', () => Turbo.visit(document.location))
  }
}
