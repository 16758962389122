import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["screen", "frame", "side", "sideInner", "layouts", "fullscreenButton"]

  connect() {

    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this
  }

  desktopView(){
    this.element.classList.add("desktop-view");
    this.element.classList.remove("mobile-view");
  }

  mobileView(){
    this.element.classList.add("mobile-view");
    this.element.classList.remove("desktop-view");
  }

  fullscreenView(){
    if (!this.element.classList.contains("fullscreen-view")){
      this.fullscreenButtonTarget.click();
    }
  }

  removeFullscreenView(){
    if (this.element.classList.contains("fullscreen-view")){
      this.fullscreenButtonTarget.click();
    }
  }

  toggleFullscreenView(){
    this.element.classList.toggle("fullscreen-view");
  }

  updateGrid(e){
    const value = e.target.value;

    this.layoutsTarget.classList.remove("grid-cols-1", "grid-cols-2", "grid-cols-3");
    this.layoutsTarget.classList.add(value);
  }

  showAddButton(e){
    const type = this.element.dataset.siteEditorType;
    const offset = this.element.getBoundingClientRect().top + window.scrollY;
    const height = this.element.offsetHeight;
    const Y = e.pageY;
    const loc = Math.abs(offset - Y);
    const buttonTopClass = type === "block" ? '.lp-add-button-block-before' : '.lp-add-button-section-before';
    const buttonBottomClass = type === "block" ? '.lp-add-button-block-after' : '.lp-add-button-section-after';
    const buttonTop = this.element.querySelectorAll(buttonTopClass);
    const buttonBottom = this.element.querySelectorAll(buttonBottomClass)[0];

    if (loc < height/2) {
      // Top
      if(buttonTop.length > 0){
        this.element.classList.add("lp-show-top-add-button");
        //buttonTop[0].classList.remove("hidden");
      }

      // Bottom
      this.element.classList.remove("lp-show-bottom-add-button");
      //buttonBottom.classList.add("hidden");
    } else {
      // Top
      if(buttonTop.length > 0){
        this.element.classList.remove("lp-show-top-add-button");
        //buttonTop[0].classList.add("hidden");
      }

      // Bottom
      this.element.classList.add("lp-show-bottom-add-button");
      //buttonBottom.classList.remove("hidden");
    }
  }

  hideAddButton(){
    this.element.classList.remove("lp-show-top-add-button");
    this.element.classList.remove("lp-show-bottom-add-button");
  }

  clickOutsideFrame(){
    const iframe = document.querySelector('#lp-editor-frame');
    let iframeContents = (iframe.contentWindow || iframe.contentDocument);
    if (iframeContents.document) iframeContents = iframeContents.document;
    iframeContents.body.click();    

    console.log("clickOutsideFrame")

      
}
  
}
