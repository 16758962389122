import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    this.prevScrollpos = window.pageYOffset;
    
    if(this.element.classList.contains("lp-sticky-scroll-up") && !this.element.classList.contains("lp-clone")){
      const element = this.element
      const clone = element.cloneNode(true);
      const parent = element.parentElement

      clone.classList.add("lp-clone");
      this.clone = clone
      parent.insertBefore(clone, element);
    }
  }

  onScroll(){
    if(this.element.classList.contains("lp-sticky-scroll-up") && !this.element.classList.contains("lp-clone") ){
      /* Get the header element and it's position */
      var headerDiv = this.element;
      var headerTop = headerDiv.offsetTop;
      var headerBottom = headerDiv.offsetTop + headerDiv.offsetHeight;
      var currentScrollPos = window.pageYOffset;

      if(window.scrollY > headerTop) {
        if (this.prevScrollpos > currentScrollPos || currentScrollPos < headerTop){ 
          this.clone.classList.remove("lp-hidden");
        } 
      } else {
        this.clone.classList.add("lp-hidden");
        this.clone.classList.remove("lp-scroll-down");
        this.clone.classList.add("lp-scroll-up");
      }
      if(window.scrollY > headerBottom) {
        /* if we're scrolling up, or we haven't passed the header,
          show the header at the top */
        if (this.prevScrollpos > currentScrollPos || currentScrollPos < headerBottom){  
          this.clone.classList.remove("lp-scroll-up");
          this.clone.classList.add("lp-scroll-down");
        } else {
          /* otherwise we're scrolling down & have passed the header so hide it */
          this.clone.classList.remove("lp-scroll-down");
          this.clone.classList.add("lp-scroll-up");
        } 
        this.prevScrollpos = currentScrollPos;
      }
    }
  }
}
