import { Controller } from "stimulus";

export default class extends Controller {

  static targets = [ "button", "iframeContainer", "iframe", "editButton", "unEditButton", "link", "container", "fullscreenButton", "designButton", "unDesignButton"]

  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this
  }

  // Make element active
  active() {
    this.element.classList.add("active");

    // Element activation complete
    setTimeout(() => {
      this.element.classList.add("activated");
    }, 200);
  }

  // Makes button active
  activateButton() {
    this.buttonTarget.classList.add("lp-active");
  }

  // Toggles activate class
  toggleActivateButton() {
    this.buttonTarget.classList.toggle("lp-active");
  }

  isActive() {

    // Disable scroll
    document.documentElement.wholePage.toggleDisableScroll();
    this.linkTarget.classList.toggle("is-active");
  }

  isActiveMobile() {

    // Disable scroll
    document.documentElement.wholePage.toggleDisableScroll();

    this.element.classList.toggle("mobile-menu-active");
    this.linkTarget.classList.toggle("is-active");
    if (this.element.classList.contains('mobile-menu-active')) {
      setTimeout(() => {
        this.element.querySelectorAll('.lp-header-navigation')[0].classList.add('move-up');
      }, 200);
      setTimeout(() => {
        this.element.querySelectorAll('.lp-header-social-links')[0].classList.add('move-up');
      }, 400);
    } else {
      setTimeout(() => {
        this.element.querySelectorAll('.lp-header-navigation')[0].classList.remove('move-up');
      }, 200);
      setTimeout(() => {
        this.element.querySelectorAll('.lp-header-social-links')[0].classList.remove('move-up');
      }, 400);
    }
  }

  // Make element active
  activeGroup() {
    const listItems = document.querySelectorAll('.list-item');
    
    listItems.forEach(element => element.classList.remove('lp-active'));

    if(this.element.closest('.list-item') !== null){
      this.element.closest('.list-item').classList.add("lp-active");
    }
  }

  // Make element inactive
  inactive() {
    this.element.classList.remove("active", "activated");
  }

  editAlt(){
    const iframe = document.querySelector('#previewIframe');   

    if (iframe === null){
      parent.document.querySelector('#preview').clickActive.edit();
      parent.document.querySelector('#preview').clickActive.designView();
    } else {
      document.querySelector('#preview').clickActive.edit();
      document.querySelector('#preview').clickActive.designView();
    }
  }

  editAction(){
    const url = this.linkTarget.dataset.url
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        'Content-Type': 'application/json',
        
      },
    })
    .then((response) => response.text())
    .then(html => Turbo.renderStreamMessage(html))
    .then(data => {
      //document.querySelector('#preview').clickActive.edit();
    });
  }

  // Show edit view
  edit() {
    // Change to edit layout
    this.element.classList.add("edit");

    // Element activation complete
    setTimeout(() => {
      this.element.classList.add("activated");
    }, 200);

    // Fade out edit button
    // Fade
    this.editButtonTarget.classList.add("opacity-0");
    this.fullscreenButtonTarget.classList.add("opacity-0");

    // Hide
    setTimeout(() => {
      this.editButtonTarget.classList.add("hidden");
      this.fullscreenButtonTarget.classList.add("hidden");
    }, 200);

    // Fade in unedit button
    // Show
    this.unEditButtonTarget.classList.remove("hidden");
    this.designButtonTarget.classList.remove("hidden");
    
    // Fade
    setTimeout(() => {
      this.unEditButtonTarget.classList.remove("opacity-0");
      this.designButtonTarget.classList.remove("opacity-0");
    });
  }

  designView(){

    const frame = document.querySelector('#previewIframe');
    const iframe = frame === null ? parent.document.querySelector('#previewIframe') : document.querySelector('#previewIframe');
    iframe.onload = function() { 
      let iframeContents = (iframe.contentWindow || iframe.contentDocument);
      if (iframeContents.document) iframeContents = iframeContents.document;

      iframeContents.body.classList.add('lp-design-view');

      iframeContents.querySelectorAll('.lp-design-viewable').forEach(element => {
        element.classList.add('lp-design-view-active')
      })
    };

    this.designButtonTarget.classList.add("opacity-0");
    setTimeout(() => {
      this.designButtonTarget.classList.add("hidden");
    }, 200);

    this.unDesignButtonTarget.classList.remove("hidden");
    setTimeout(() => {
      this.unDesignButtonTarget.classList.remove("opacity-0");
    });


    
  }

  designViewOn(){
    const frame = document.querySelector('#previewIframe');
    const iframe = frame === null ? parent.document.querySelector('#previewIframe') : document.querySelector('#previewIframe');
    
    let iframeContents = (iframe.contentWindow || iframe.contentDocument);
    if (iframeContents.document) iframeContents = iframeContents.document;

    iframeContents.body.classList.add('lp-design-view');
    iframeContents.querySelectorAll('.lp-design-viewable').forEach(element => {
      element.classList.add('lp-design-view-active')
    })
  }

  designViewOff(){
    const frame = document.querySelector('#previewIframe');
    const iframe = frame === null ? parent.document.querySelector('#previewIframe') : document.querySelector('#previewIframe');
    
    let iframeContents = (iframe.contentWindow || iframe.contentDocument);
    if (iframeContents.document) iframeContents = iframeContents.document;

    iframeContents.body.classList.remove('lp-design-view');
    iframeContents.querySelectorAll('.lp-design-viewable').forEach(element => {
      element.classList.remove('lp-design-view-active')
    })
  }

  unDesignView(){

    const frame = document.querySelector('#previewIframe');
    const iframe = frame === null ? parent.document.querySelector('#previewIframe') : document.querySelector('#previewIframe');
    iframe.onload = function() { 
      let iframeContents = (iframe.contentWindow || iframe.contentDocument);
      if (iframeContents.document) iframeContents = iframeContents.document;

      iframeContents.body.classList.remove('lp-design-view');
    };

    this.unDesignButtonTarget.classList.add("opacity-0");
    setTimeout(() => {
      this.unDesignButtonTarget.classList.add("hidden");
    }, 200);

    this.designButtonTarget.classList.remove("hidden");
    setTimeout(() => {
      this.designButtonTarget.classList.remove("opacity-0");
    });
  }

  // Show normal view
  unedit() {
    const url = this.iframeContainerTarget.dataset.pageUrl

    // Change iframe to edit page
    this.iframeTarget.src = url;

    this.uneditWithoutUrlChange()
  }

  // Show normal view
  uneditWithoutUrlChange() {

    // Change to edit layout
    this.element.classList.remove("edit", "activated");

    // Fade
    this.unEditButtonTarget.classList.add("opacity-0");
    this.designButtonTarget.classList.add("opacity-0");
    this.unDesignButtonTarget.classList.add("opacity-0");
    // Hide
    setTimeout(() => {
      this.unEditButtonTarget.classList.add("hidden");
      this.designButtonTarget.classList.add("hidden");
      this.unDesignButtonTarget.classList.add("hidden");
    }, 200);

    // Show
    this.editButtonTarget.classList.remove("hidden");
    this.fullscreenButtonTarget.classList.remove("hidden");
    // Fade
    setTimeout(() => {
      this.editButtonTarget.classList.remove("opacity-0");
      this.fullscreenButtonTarget.classList.remove("opacity-0");
    });
  }

  updateUrls(event){
    const link = this.linkTarget;
    const type = link.dataset.type;
    const iframe = document.getElementById('previewIframe');
    const preview = iframe === null ? parent.document.querySelector('#preview') : document.querySelector('#preview');
    const generateUrlsPath = preview.dataset.urls
    const pageID = link.dataset.pageId
    const postID = "postId" in link.dataset ? link.dataset.postId : null
    const highlightOnly = "highlightOnly" in link.dataset ? link.dataset.highlightOnly : false
    const list = "list" in link.dataset ? link.dataset.list : null
    const update_frame = "updateFrame" in link.dataset ? link.dataset.updateFrame : null
    const params = { page_id: pageID, highlight_only: highlightOnly }
    
    if(event && type === "button"){
      event.preventDefault();
    }
    if (postID){
      params.post_id = postID;
      const fields = document.querySelectorAll('.preview-post-id-field');
      fields.forEach(field => field.value = postID);
    }
    if (list){
      params.list = list;
    }
    if (update_frame){
      params.update_frame = update_frame;
    }
    
    fetch(generateUrlsPath + "?" + new URLSearchParams(params), {
      method: 'GET',
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        'Content-Type': 'application/json',
        
      },
    })
    .then((response) => response.text())
    .then(data => {
      const response = JSON.parse(data);
      const homeUrl = response.home;
      const pageID = response.pageID;
      const postID = response.postID;
      const pageUrl = response.page;
      const postUrl = response.post;
      const editUrl = response.edit;
      const browserUrl = response.browser;
      const designUrl = response.design;
      const updateFrame = response.updateFrame;
      const highlightOnly = response.highlightOnly;
      const iframe = document.getElementById('previewIframe');
      
      iframe === null ? parent.window.history.pushState({}, null, browserUrl) : window.history.pushState({}, null, browserUrl);
      this.editButtonUrls(pageID, postID, pageUrl, postUrl, editUrl, homeUrl, designUrl);
      if (updateFrame){
        this.updateFrame();
      }
      
      if (highlightOnly === "true"){
        document.querySelector(`#page-item-${pageID}`).querySelectorAll('.list-item')[0].classList.add("lp-active");
      }
    });
  }

  editButtonUrls(pageID, postID, pageUrl, postUrl, editUrl, homeUrl, designUrl){
    const iframe = document.getElementById('previewIframe');
    const preview = iframe === null ? parent.document.querySelector('#previewIframeContainer') : document.querySelector('#previewIframeContainer');
    const homeLink = document.querySelectorAll('.home-btn');
    const createPostBtn = document.querySelectorAll('.create-post-btn');
    const pageIdFields = document.querySelectorAll('.lp-page-id-field');

    preview.dataset.editPageUrl = editUrl
    preview.dataset.pageUrl = pageUrl
    preview.dataset.designPageUrl = designUrl

    preview.dataset.pageId = pageID
    preview.dataset.postId = postID

    homeLink.forEach(link => {
      link.href = homeUrl;
    })

    createPostBtn.forEach(btn => {
      btn.dataset.url = postUrl
    })

    pageIdFields.forEach(field => {
      field.value = pageID
    })
  }

  editItem(event){

    const link = this.linkTarget;
    const url = link.dataset.editItemUrl;

    fetch(url, {
      method: 'GET',
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        'Content-Type': 'application/json',
        
      },
    })
    .then((response) => response.text())
    .then(html => Turbo.renderStreamMessage(html))
  }

  updateFrame(){

    const frame = document.querySelector('#previewIframe');
    const link = this.linkTarget;
    const previewIframeContainer = link.dataset.type === "edit" ? this.iframeContainerTarget : frame === null ? parent.document.querySelector('#previewIframeContainer') : document.querySelector('#previewIframeContainer');
    const iframe = link.dataset.type === "edit" ? this.iframeTarget : frame === null ? parent.document.querySelector('#previewIframe') : document.querySelector('#previewIframe');

    if (link.dataset.type === "design"){
      iframe.src = previewIframeContainer.dataset.editPageUrl
    } else if (link.dataset.type === "edit"){
      iframe.src = previewIframeContainer.dataset.editPageUrl
    } else {
      iframe.src = previewIframeContainer.dataset.pageUrl
    }
  }

  editOnDoubleClick(){
    const element = this.element
    if (!element.classList.contains("edit") || document.body.classList.contains("design-view") ){
      this.iframeTarget.contentWindow.document.ondblclick=function(){
        element.clickActive.updateFrame();
        element.clickActive.edit();
        element.clickActive.unDesignView();
        document.body.layout.editFromEditor();
        element.clickAppear.closeSidePanel();
      }
    }
  }
}
