import { Controller } from "stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  static values = { url: String }

  connect() {
    new TomSelect(this.element,{
      create: false,
    });
  }
}