import { Controller } from "stimulus"
let debounce = require('lodash/debounce');

export default class extends Controller {
  static targets = [ "field", "button"]

  connect() {}

  showButton(){
    const confirmText = this.element.dataset.confirmText;
    const field = this.fieldTarget;
    const button = this.buttonTarget;
    const activate = field.value == confirmText;

    console.log("activate", activate);
    console.log("confirmText", confirmText);
    console.log("field.value", field.value);
    if(activate){
      button.disabled = false
    } else {
      button.disabled = true
    }
  }
}
