import { Controller } from "stimulus";
import SlimSelect from 'slim-select'
import 'slim-select/dist/slimselect.min.css';

export default class extends Controller {
  
    connect() {
        new SlimSelect({
            select: this.element
        })
    }
}