import { Controller } from 'stimulus'
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling
import 'tippy.js/themes/light.css';
import 'tippy.js/dist/border.css';

export default class extends Controller {

  connect() {
    const delay = this.element.hasAttribute("data-delay") ? this.element.dataset.delay : 100
    const trigger = this.element.hasAttribute("data-trigger") ? this.element.dataset.trigger : "mouseenter focus"

    tippy(this.element, {
      content: this.element.dataset.content,
      delay: [delay, null],
      trigger: trigger,
      theme: 'light',
    });
  }
}
