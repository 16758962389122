import { Controller } from "stimulus";
import Sortable from 'sortablejs';

export default class extends Controller {

 static targets = ["items"]

  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this
    
    this.sort();
  }

  sort(){
    if (this.hasItemsTarget){
      const element = this.element
      const items = this.itemsTarget;
      const url = element.dataset.sortUrl;
      const iframeContainer = document.querySelector('#lp-editor-screen-inner');

      const sortable = Sortable.create(items, {
        handle: ".lp-sort-handle",
        filter: ".lp-sort-ignore",
        ghostClass: "lp-sort-ghost",
        chosenClass: "lp-sort-chosen",
        dragClass: "lp-sort-drag",
        // Element is chosen
        onChoose: function (/**Event*/evt) {
          const item = evt.item.querySelectorAll('.lp-sort-item')[0];
          const type = item.dataset.type;
          items.classList.add("lp-sort-choose");

          if(type === "section"){
            item.clickAppear.fadeOutInstant();
            iframeContainer.classList.add("lp-zoom-out")
          }
        },

        // Element is unchosen
        onUnchoose: function(/**Event*/evt) {
          // same properties as onEnd
          items.classList.remove("lp-sort-choose");
          iframeContainer.classList.remove("lp-zoom-out")
        },
        onStart: function (/**Event*/evt) {
          const iframe = document.querySelector('#lp-editor-frame');
          let iframeContents = (iframe.contentWindow || iframe.contentDocument);
          if (iframeContents.document) iframeContents = iframeContents.document;
          const draggedElementId = evt.item.querySelectorAll('.lp-sort-item')[0].dataset.elementId;
          const draggedElement = iframeContents.querySelector(draggedElementId);

          // Add dragged focus class
          draggedElement.classList.add("lp-focus-dragged");
        },
        onMove: function (/**Event*/evt, /**Event*/originalEvent) {
          const iframe = document.querySelector('#lp-editor-frame');
          let iframeContents = (iframe.contentWindow || iframe.contentDocument);
          if (iframeContents.document) iframeContents = iframeContents.document;
          const draggedElementId = evt.dragged.querySelectorAll('.lp-sort-item')[0].dataset.elementId;
          const relatedElementId = evt.related.querySelectorAll('.lp-sort-item')[0].dataset.elementId;
          const draggedElement = iframeContents.querySelector(draggedElementId);
          const relatedElement = iframeContents.querySelector(relatedElementId); 

          if (draggedElement !== null && relatedElement !== null){
            
            if (evt.willInsertAfter){
              draggedElement.parentElement.insertBefore(relatedElement, draggedElement);
            } else {
              draggedElement.parentElement.insertBefore(draggedElement, relatedElement);
            }
          } 
        },
        // Changed sorting within list
        onEnd: function (/**Event*/evt) {
          const iframe = document.querySelector('#lp-editor-frame');
          let iframeContents = (iframe.contentWindow || iframe.contentDocument);
          if (iframeContents.document) iframeContents = iframeContents.document;
          const draggedElementId = evt.item.querySelectorAll('.lp-sort-item')[0].dataset.elementId;
          const draggedElement = iframeContents.querySelector(draggedElementId);
          const item = evt.item.querySelectorAll('.lp-sort-item')[0];
          const type = item.dataset.type;
          const typeClass = `.lp-${type}`;


          // Remove dragged focus class
          draggedElement.classList.remove("lp-focus-dragged");

          let order = [];
          
          const elementID = evt.item.dataset.id;
          const elementPosition = evt.newIndex;

          // Push to order array
          const sortItems = items.querySelectorAll(typeClass);

          for (let [i, item] of sortItems.entries()) {
            const id = item.dataset.id;

            // Remove dragged focus class
            item.classList.remove("lp-focus-dragged");

            order.push({ id: id, position: elementID == id ? elementPosition : i });
          }

          // Send order array to Pages controller
          const data = { items: order };
          
          fetch(url, {
            method: 'PATCH',
            headers: {
              Accept: "text/vnd.turbo-stream.html",
              'Content-Type': 'application/json',
              
            },
            body: JSON.stringify(data),
          })
          .then((response) => response.text())
          .then(html => Turbo.renderStreamMessage(html))
        }
      });
    }
  }
}
