import { Controller } from 'stimulus'
import {loadStripe} from '@stripe/stripe-js';
import Rails from "@rails/ujs";



export default class extends Controller {
  static targets = [ "cardField", "errorElement", "tokenField", "nameField", "submitButton", "addressLine1Field", "addressCityField", "addressStateField", "addressZipField", "addressCountryField"]

  connect() {
    const key = this.element.dataset.stripeKey
    const stripe = Stripe(key);
    const cardField = this.cardFieldTarget
    const errorElement = this.errorElementTarget;

    // Set up Stripe.js and Elements to use in checkout form
    const elements = stripe.elements({
      fonts: [
        {
          cssSrc: 'https://fonts.googleapis.com/css2?family=Inter&display=swap',
        }
      ]
    });

    // Elements style
    const style = {
      base: {
        iconColor: window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? '#9ca3af' : '#9ca3af',
        color: window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? '#fff' : '#000000',
        fontFamily: 'Inter',
        fontSize: '14px',
        '::placeholder': {
          color: window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? '#9ca3af' : '#9ca3af'
        },
        lineHeight: '1.5'
      },
      invalid: {
        color: '#ef4444',
        iconColor: '#ef4444'
      }
    };

    // Mount card
    const cardElement = elements.create("card", { hidePostalCode: true, style: style });
    cardElement.mount(cardField);

    // Handle real-time validation errors from the card Element.
    cardElement.addEventListener('change', function(e) {
      if (e.error) {
        cardField.classList.add("field_with_errors");
        errorElement.textContent = e.error.message;
      } else {
        cardField.classList.remove("field_with_errors");
        errorElement.textContent = "";
      }
    });

    // Handle form submission.
    const submitButton = this.submitButtonTarget;
    const form = this.element;
    const tokenField = this.tokenFieldTarget;
    const controller = this;
    const plansConfirmPage = document.querySelector('#plan-confirm')

    

    submitButton.addEventListener('click', function (event) {
      event.preventDefault();

      // Add loader to summary
      if (plansConfirmPage !== null){
        plansConfirmPage.plans.summaryLoad();
      }

      // Collect data
      const nameField = controller.nameFieldTarget;
      const addressLine1 = controller.addressLine1FieldTarget;
      const addressCity = controller.addressCityFieldTarget;
      const addressState = controller.addressStateFieldTarget;
      const addressZip = controller.addressZipFieldTarget;
      const addressCountry = controller.addressCountryFieldTarget;

      // Button load
      form.formSubmit.loadAction();

      // Billing address
      const data = {
        name: nameField.value,
        address_line1: addressLine1.value,
        address_city: addressCity.value,
        address_state: addressState.value,
        address_zip: addressZip.value,
        address_country: addressCountry.value
      };

      stripe.createToken(cardElement, data).then(function (result) {
          if (result.error) {
            // Button unload
            form.formSubmit.unloadAction();

            // Inform the user if there was an error.
            errorElement.textContent = result.error.message;

            // Add loader to summary
            if (plansConfirmPage !== null){
              plansConfirmPage.plans.summaryLoadRemove();
            }
          } else {

            // Insert the token ID into the form so it gets submitted to the server
            tokenField.value = result.token.id;

            // Submit the form
            form.formSubmit.submit();
          }
      });
    });
  }

}
