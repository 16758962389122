import { Controller } from "stimulus"
let debounce = require('lodash/debounce');

export default class extends Controller {

  connect() {
    const visibility = this.element.dataset.visibility;
    const elementId = this.element.dataset.elementId;
    const iframe = document.querySelector('#lp-editor-frame');
    

    let iframeContents = (iframe.contentWindow || iframe.contentDocument);
    if (iframeContents.document) iframeContents = iframeContents.document;
    
    const element = iframeContents.querySelector(elementId);
    
    
    if (visibility == "hidden") {
      element.classList.add("hidden");
    } else {
      element.classList.remove("hidden");
    }
  }
}
