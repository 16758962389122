import { Controller } from "stimulus"
import Quill from 'quill/quill';
import Toolbar from 'quill/modules/toolbar';
import Snow from 'quill/themes/snow';
import Bold from 'quill/formats/bold';
import Italic from 'quill/formats/italic';
import Strike from 'quill/formats/strike';
import Blockquote from 'quill/formats/blockquote';
import Link from 'quill/formats/link';

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

let BlockEmbed = Quill.import('blots/block/embed');

let debounce = require('lodash/debounce');

export default class extends Controller {
  

  static targets = ["editor", "toolBar", "toolBarContainer", "linkButton", "linkModal", "urlField", "field"]

  connect(){
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this

    var icons = Quill.import('ui/icons');

    icons['bold'] = '<i class="li-regular li-bold"></i>';
    icons['italic'] = '<i class="li-regular li-italic"></i>';
    icons['link'] = '<i class="li-regular li-link"></i>';
    icons['blockquote'] = '<i class="li-regular li-quote-left"></i>';
    icons['align']['center'] = '<i class="li-regular li-align-center"></i>';
    icons['align'][''] = '<i class="li-regular li-align-left"></i>';
    icons['align']['right'] = '<i class="li-regular li-align-right"></i>';
    icons['align']['justify'] = '<i class="li-regular li-align-justify"></i>';
    icons['indent']['-1'] = '<i class="li-regular li-indent-decrease"></i>';
    icons['indent']['+1'] = '<i class="li-regular li-indent"></i>';
    icons['strike'] = '<i class="li-regular li-strike"></i>';
    icons['underline'] = '<i class="li-regular li-underline"></i>';
    icons['list']['bullet'] = '<i class="li-regular li-list-ul"></i>';
    icons['list']['ordered'] = '<i class="li-regular li-list-ol"></i>';
    icons['video'] = '<i class="li-regular li-video"></i>';
    icons['color'] = '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><rect class="ql-color-label" width="20" height="20" x="92" y="81" fill="#D8D8D8" fill-rule="evenodd" rx="10" transform="translate(-92 -81)"/></svg>';
    icons['background'] = '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><rect class="ql-color-label" width="20" height="20" x="92" y="81" fill="transparent" fill-rule="evenodd" rx="10" transform="translate(-92 -81)"/></svg>';
    
    let ColorStyle = Quill.import('attributors/style/color');
    Quill.register(ColorStyle, true);

    Quill.register({
      'modules/toolbar': Toolbar,
      'themes/snow': Snow,
      'formats/bold': Bold,
      'formats/italic': Italic,
      'formats/strike': Strike,
      'formats/blockquote': Blockquote,
      'formats/link': Link,
    });

    

    class ImageBlot extends BlockEmbed {
      static create(value) {
        let node = super.create();
        node.setAttribute('alt', value.alt);
        node.setAttribute('src', value.url);

        console.log("node", node)
        return node;
      }
      
      static value(node) {
        return {
          alt: node.getAttribute('alt'),
          url: node.getAttribute('src')
        };
      }
    }
    ImageBlot.blotName = 'image';
    ImageBlot.tagName = 'img';

    Quill.register(ImageBlot);


    const options = {
      theme: 'snow',
      modules: {
        toolbar: this.toolBarTarget
      },
    };

    const quill = new Quill(this.editorTarget, options);
    const element = this.element;
    const editor = this.editorTarget;
    const toolBar = this.toolBarTarget;
    const settingId = element.dataset.settingId
    const type = editor.dataset.type

    let field = this.hasFieldTarget ? this.fieldTarget : null;

    const svgs = toolBar.querySelectorAll('.ql-picker:not(.ql-color-picker) .ql-picker-label svg')
    svgs.forEach((svgElem) => {
      svgElem.innerHTML = `<g fill="none" fill-rule="evenodd" transform="translate(.378 .782)"><rect width="20" height="20" x=".622"/><path fill="#000" d="M15.5216818,10.9497475 L6.32929367,20.1421356 C5.93876938,20.5326599 5.3056044,20.5326599 4.91508011,20.1421356 C4.52455582,19.7516113 4.52455582,19.1184464 4.91508011,18.7279221 L13.4251869,10.217 L4.91508011,1.70710678 C4.55459615,1.34662282 4.52686661,0.779391764 4.8318915,0.387100557 L4.91508011,0.292893219 C5.3056044,-0.0976310729 5.93876938,-0.0976310729 6.32929367,0.292893219 L6.32929367,0.292893219 L15.5216818,9.48528137 C15.7019238,9.66552336 15.7989772,9.89745211 15.812842,10.1333568 L15.812842,10.3016721 C15.7989772,10.5375767 15.7019238,10.7695055 15.5216818,10.9497475 Z" transform="rotate(90 10.218 10.218)"/></g>`
      svgElem.setAttribute('viewBox', '0 0 21 21')
    });

    if (toolBar.classList.contains("lp-toolbar-fixed")){
      const sidePanel = document.querySelectorAll(".side-inner")[0];
      if (sidePanel){
        
        const sidePanelHeader = document.querySelectorAll(".lp-side-header")[0]

        sidePanel.addEventListener('scroll', function(e) {
          const scrollTop = sidePanel.scrollTop + sidePanelHeader.offsetHeight
          
          if((scrollTop > element.offsetTop) && (scrollTop < element.offsetTop + element.offsetHeight)){
            toolBar.style.top = `${sidePanel.scrollTop - sidePanelHeader.offsetHeight}px`;
          }
        });
      }
    }
    quill.on('text-change', function(delta, oldDelta, source) {
      let setting;
      let form;

      const style = editor.dataset.style;
      const editorValue = editor.querySelector('.ql-editor').innerHTML;
      const noFrameEditor = ["link-label", "button-label"].includes(style)

      function editorUpdate(form, element, setting, editorValue){
        // Submit form.
        form.formSubmit.submitDebounce();
    
        if (!noFrameEditor){
          const otherEditor = setting.querySelectorAll(".lp-editor")[0];
          const otherQuill = Quill.find(otherEditor);
          const otherQuillDelta = otherQuill.clipboard.convert(editorValue);
    
          // Update other Quill
          otherQuill.setContents(otherQuillDelta, 'silent');
    
          if(type != "frame"){
            const elementContainer = otherEditor.closest(".lp-element");
            const strippedValue = editorValue.replace(/<[^>]*>?/gm, '');
    
            // Hide/Show text
            if((strippedValue == null || strippedValue == "")){
              elementContainer.classList.add("hidden");
            } else {
              elementContainer.classList.remove("hidden");
            }
          }
        }
      }

      if(type == "frame"){
        setting = parent.document.querySelector(`#${settingId}`);
        const settingElement = setting.querySelectorAll('.lp-setting')[0];
        field = setting.querySelectorAll('.lp-field')[0];
        form = settingElement.closest('form');
        editorUpdate(form, element, setting, editorValue);
      } else {
        const iframe = document.querySelector('#lp-editor-frame');

        if (iframe){
          let iframeContents = (iframe.contentWindow || iframe.contentDocument);
          if (iframeContents.document) iframeContents = iframeContents.document;
          setting = iframeContents.querySelector(`#${settingId}`);
          form = editor.closest('form');
          editorUpdate(form, element, setting, editorValue);
        }
      }    

      // Update value field.
      field.value = editorValue;
      
      if (noFrameEditor){
        // Must be after form submit otherwise there will be lag.
        if (element.dataset.controller.includes("settings")){
          element.settings.updateFrame();
          element.settings.autosave();
        }
      }
    });
  }

  

  hideFormButtons(){
    const formButtons = parent.document.getElementById('form-buttons-container');

    formButtons.clickAppear.hideHiddenElement();
  }

  showLinkModal(event){

    // Show/hide
    this.linkModalTarget.classList.remove("hidden");

    // Fade
    setTimeout(() => {
      this.linkModalTarget.classList.remove("opacity-0");
    });
    
  }

  createLink(){
    const quill = Quill.find(this.editorTarget);
    
    let value = this.urlFieldTarget.value;
    quill.format('lesslink', value);

  }

  showToolbar(event){

    
    // Show/hide
    this.toolBarContainerTarget.classList.remove("hidden");

    // Fade
    setTimeout(() => {
      this.toolBarContainerTarget.classList.remove("opacity-0");
    });
  }

  imageSelect(url){
    var div = this.editorTarget;
    var quill = Quill.find(div);
    let range = quill.getSelection(true);
    quill.insertText(range.index, '\n', Quill.sources.USER);
    quill.insertEmbed(range.index + 1, 'image', {
      alt: 'Quill Cloud',
      url: url
    }, Quill.sources.USER);
    quill.setSelection(range.index + 2, Quill.sources.SILENT);
  }
}
