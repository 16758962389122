import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
      const mode = e.matches ? "dark" : "light";
      this.updateFrame(mode)
    });
  }

  hex2rgba(hex, alpha = 1){
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  };


  updateElement(mode, element){
    const scheme = element.dataset.colorScheme;
    const colorSchemeData = this.element
    const darkMode = mode == "dark";
    const body = this.element.closest("body");
    const preview = body.dataset.preview == "true";  

    // Background
    if (scheme == "scheme-1"){
      const bodyBackgroundColor = colorSchemeData.getAttribute(`data-${scheme}-background${darkMode ? "-dark" : ""}${preview ? "-preview" : ""}`)
      body.style.backgroundColor = bodyBackgroundColor;
    }

    // Background
    const backgroundColor = colorSchemeData.getAttribute(`data-${scheme}-background${darkMode ? "-dark" : ""}${preview ? "-preview" : ""}`)
    element.style.backgroundColor = backgroundColor;

    // Text
    const textColor = colorSchemeData.getAttribute(`data-${scheme}-text${darkMode ? "-dark" : ""}${preview ? "-preview" : ""}`);
    
    element.style.color = textColor;
    
    element.querySelectorAll(".lp-type-heading, .lp-type-body, .lp-content a").forEach(text => {
      text.style.color = textColor;
      text.querySelectorAll(".ql-editor").forEach(editorText => {
        editorText.style.color = textColor;
      });
    });

    element.querySelectorAll(".lp-input").forEach(input => {
      input.style.color = textColor;
      if (textColor){
        input.style.borderColor = this.hex2rgba(textColor, 0.7);
        input.style.setProperty("--lp-placeholder", this.hex2rgba(textColor, 0.7));
      }
    });
    element.querySelectorAll(".lp-light-dark-mode .slider").forEach(slider => {
      slider.style.color = textColor;
    });
    

    // Solid button background
    const solidButtonBackgroundColor = colorSchemeData.getAttribute(`data-${scheme}-solid-button-background${darkMode ? "-dark" : ""}${preview ? "-preview" : ""}`);
    element.querySelectorAll(".lp-button-solid").forEach(button => {
      button.style.backgroundColor = solidButtonBackgroundColor
    });

    // Solid button label
    const solidButtonLabelColor = colorSchemeData.getAttribute(`data-${scheme}-solid-button-label${darkMode ? "-dark" : ""}${preview ? "-preview" : ""}`);
    element.querySelectorAll(".lp-button-solid").forEach(button => {
      button.style.color = solidButtonLabelColor
    });

    // Outline button border
    const outlineButtonBorderColor = colorSchemeData.getAttribute(`data-${scheme}-outline-button-border${darkMode ? "-dark" : ""}${preview ? "-preview" : ""}`);
    element.querySelectorAll(".lp-button-outline").forEach(button => {
      button.style.borderColor = outlineButtonBorderColor;
    });

    // Outline button label
    const outlineButtonLabelColor = colorSchemeData.getAttribute(`data-${scheme}-outline-button-label${darkMode ? "-dark" : ""}${preview ? "-preview" : ""}`);
    element.querySelectorAll(".lp-button-outline").forEach(button => {
      button.style.color = outlineButtonLabelColor;
    });

    // Border color
    const darkBorder = "rgba(255,255,255,0.1)";
    const lightBorder = "rgba(0,0,0,0.1)";
    const borderColor = darkMode ? darkBorder : lightBorder;

    element.querySelectorAll(".lp-border-color").forEach(border => {
      border.style.borderColor = borderColor;
    });
  }
  

  updateFrame(mode){
    const body = this.element.closest("body");
    const elements = body.querySelectorAll('.lp-section[data-color-scheme], .lp-card[data-color-scheme]');

    elements.forEach(element => {
      this.updateElement(mode, element)
    });
  }
}
