import { Controller } from "stimulus"
import 'lazysizes';

export default class extends Controller {

  connect() {
  }

  bg(e){
    const bg = e.target.getAttribute('data-bg');
    
    if(bg){
        e.target.style.backgroundImage = 'url(' + bg + ')';
    }
  }
}
