import { Controller } from "stimulus";
import Sortable from 'sortablejs';

export default class extends Controller {

 static targets = ["sortable"]

  connect() {
    document.querySelector('#page-list-sort').sort.sort()
  }
}
