import { Controller } from "stimulus"
let debounce = require('lodash/debounce');

export default class extends Controller {

  static targets = ["button"]

  connect() {
    const darkModeClass = "lp-dark-mode";
    const lightModeClass = "lp-light-mode";

    document.body.classList.remove(darkModeClass, lightModeClass);
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      document.body.classList.add(darkModeClass);
      this.buttonTarget.value = darkModeClass;
    } else {
      document.body.classList.add(lightModeClass);
      this.buttonTarget.value = lightModeClass;
      this.buttonTarget.click();
    }
  }
}
