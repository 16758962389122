import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "container", "loader" ]

  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this
  }

  load(){
    const size = this.element.hasAttribute("data-load-size") ? this.element.dataset.loadSize : null
    this.containerTarget.innerHTML = `<div class='loader ${size} opacity-100'></div>`;
  }

  showLoader(){
    const loader = this.loaderTarget;

    loader.classList.remove("hidden");
    setTimeout(() => {
      loader.classList.remove("opacity-0");
    });
  }

  showAllLoaders(){
    this.element.querySelectorAll('.load-container').forEach(loader => {
      loader.load.showLoader();
    });
  }
}
