import { Controller } from "stimulus"
import "@melloware/coloris/dist/coloris.css";
import Coloris from "@melloware/coloris";

export default class extends Controller {

  connect() {
    const swatches = this.element.dataset.swatches;

    const iframe = document.querySelector('#lp-editor-frame');
    let iframeContents = (iframe.contentWindow || iframe.contentDocument);
    if (iframeContents.document) iframeContents = iframeContents.document;
    const colorSchemeData = iframeContents.querySelector('#color-scheme-data');
    const body = iframeContents.body;

    Coloris.init();
    Coloris({
      el: ".lp-color-picker",
      wrap: false,
      themeMode: "auto",
      margin: 10,
      swatches: swatches ? JSON.parse(swatches) : null,
      onChange: (color, input) => {
        const scheme = input.dataset.scheme;
        const id = input.dataset.id;
        const attribute = `data-${scheme}-${id}`;
        const manualDarkMode = body.classList.contains("lp-dark-mode");
        const manualLightMode = body.classList.contains("lp-light-mode");
        const browserDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
        const mode = browserDarkMode ? manualLightMode ? "light" : "dark" : manualDarkMode ? "dark" : "light";

        // Update color scheme data
        colorSchemeData.setAttribute(attribute, color); 

        input.style.backgroundColor = color
        input.closest(".lp-setting").settings.autosave();

        // Change color within frame
        colorSchemeData.colorScheme.updateFrame(mode)
      }
    });
  }
}
