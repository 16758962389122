// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "controllers"
import 'stylesheets/application'

//import 'packs/carousel'

Rails.start()
ActiveStorage.start()

// Stripe
import {loadStripe} from '@stripe/stripe-js';
(async () => {
    await loadStripe('pk_test_51Hvg23ADpsAdj0mbAnKC1InhPKwdOrdRYRBpwOp3vEfMbwOnQpeZF9XKtCdIah82z7zp2BkS8WXzVagQtsf25GXC00hPXEeoY8');
})();