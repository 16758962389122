import { Controller } from "stimulus"
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';

export default class extends Controller {

  static targets = ["image", "cropButton", 'ratioField', 'ratioButton', 'brightness', 'contrast', 'grayscale', 'saturate', 'sepia', 'blur', 'rotateField', 'rotate', 'flip' ]

  connect() {
    const ratioField = this.ratioFieldTarget;
    const image = this.imageTarget;

    const elements = document.getElementsByTagName('img');

    const blurValue = this.blurTarget.value;

    const blur = this.numberPercentage(0.1, this.blurTarget.value);
    const brightness = 100 + this.numberPercentage(70, this.brightnessTarget.value);
    const contrast = 100 + this.numberPercentage(50, this.contrastTarget.value);
    const saturate = 100 + +this.saturateTarget.value;
    const grayscale = +this.grayscaleTarget.value;
    const sepia = +this.sepiaTarget.value;
    const effects = `blur(${blur}rem) brightness(${brightness}%) contrast(${contrast}%) grayscale(${grayscale }%) saturate(${saturate}%) sepia(${sepia}%)`


    const cropper = new Cropper(image, {
      aspectRatio: this.cropButtonTarget.dataset.ratio,
      viewMode: 1,
      autoCropArea: 1,
      background: false,
      movable: false,
      zoomOnTouch: false,
      zoomOnWheel: false,
      autoCrop: false,
      ready() {

        // Set effects to Cropper images
        cropper.image.style.filter = effects;
        cropper.viewBoxImage.style.filter = effects;

      },
      crop(event) {

        const width = event.detail.width;
        const height = event.detail.height;
        const x = event.detail.x;
        const y = event.detail.y;
        const cropValue = `${width}x${height}+${x}+${y}`;

        // Set crop value
        ratioField.value = cropValue
      },
    });
  }

  numberPercentage(number, percentage){
    const value = number === 0 ? 0 : (+number / 100) * +percentage;

    return value
  }

  crop(){
    this.imageTarget.cropper.crop();
  }

  changeRatio(event){

    const ratio = event.currentTarget.dataset.value;

    this.imageTarget.cropper.setAspectRatio(ratio);
  }

  clear(){
    this.imageTarget.cropper.clear();
  }

  adjustImage(event){
    const blur = this.numberPercentage(0.1, this.blurTarget.value);
    const brightness = 100 + this.numberPercentage(70, this.brightnessTarget.value);
    const contrast = 100 + this.numberPercentage(50, this.contrastTarget.value);
    const saturate = 100 + +this.saturateTarget.value;
    const grayscale = +this.grayscaleTarget.value;
    const sepia = +this.sepiaTarget.value;
    const effects = `blur(${blur}rem) brightness(${brightness}%) contrast(${contrast}%) grayscale(${grayscale }%) saturate(${saturate}%) sepia(${sepia}%)`
    
    // Set effects to Cropper images

    this.imageTarget.cropper.image.style.filter = effects; // Not working in Safari for some reason.
    // Temporary solution while above not working in Safari
    this.imageTarget.cropper.canvas.innerHTML = this.imageTarget.cropper.image.outerHTML
    this.imageTarget.cropper.viewBoxImage.style.filter = effects;
    
  }

  flip(event){

    const value = event.currentTarget.dataset.value;
    const data = this.imageTarget.cropper.getImageData();


    if (data.rotate !== undefined){
      if (data.rotate == 90 || data.rotate == 270 || data.rotate == -90 || data.rotate == -270){
        if (value === "x"){
          this.imageTarget.cropper.scale(data.scaleX, -data.scaleY);
        } else if (value === "y") {
          this.imageTarget.cropper.scale(-data.scaleX, data.scaleY);
        }
      } else {
        if (value === "x"){
          this.imageTarget.cropper.scale(-data.scaleX, data.scaleY);
        } else if (value === "y") {
          this.imageTarget.cropper.scale(data.scaleX, -data.scaleY);
        }
      }
      // Temporary solution while above not working in Safari
      this.imageTarget.cropper.canvas.innerHTML = this.imageTarget.cropper.image.outerHTML
    }
  }

  rotate(event){
    event.preventDefault();

    const rotateField = this.rotateFieldTarget;
    const rotate = this.rotateTarget;
    const rotateValue = event.currentTarget.dataset.value;
    const initialDegrees = rotateField.value;
    const data = this.imageTarget.cropper.getImageData();


    if (data.rotate !== undefined){
      const rotation = initialDegrees == 270 || initialDegrees == -270 ? 0 : +initialDegrees + +rotateValue;

      rotateField.value = rotation;
      this.rotateImage(rotateValue, rotation);
    }
  }

  rotateImage(degrees, rotation) {

    // Get data
    const data = this.imageTarget.cropper.getCropBoxData();
    const contData = this.imageTarget.cropper.getContainerData();
    const imageData = this.imageTarget.cropper.getContainerData();

    // Set data of cropbox to avoid unwanted behavior due to strict mode.
    const leftNew = (contData.width / 2) - 1;

    data.width = 2;
    data.height = 2;
    data.top = 0;
    data.left = leftNew;
    this.imageTarget.cropper.setCropBoxData(data);

    // Rotate
    this.imageTarget.cropper.rotate(degrees);

    // Get canvas data
    const canvData = this.imageTarget.cropper.getCanvasData();
    const heightOld = canvData.height; // Calculate new height and width based on the container dimensions
    const heightNew = contData.height;
    const koef = heightNew / heightOld;
    const widthNew = canvData.width * koef;

    canvData.height = heightNew;
    canvData.width = widthNew;
    canvData.top = 0;
    if (canvData.width >= contData.width) {
      canvData.left = 0;
    } else {
      canvData.left = (contData.width - canvData.width) / 2;
    }
    this.imageTarget.cropper.setCanvasData(canvData);

    // Set cropper "back" to full crop
    data.left = 0;
    data.top = 0;
    data.width = canvData.width;
    data.height = canvData.height;
    this.imageTarget.cropper.setCropBoxData(data);

    // Reset crop ratio
    const ratio = this.cropButtonTarget.dataset.ratio;
    const ratioRotated = this.cropButtonTarget.dataset.ratioRotated;

    if (rotation == -90 || rotation == -270 || rotation == 90 || rotation == 270 ) {
      this.imageTarget.cropper.setAspectRatio(ratioRotated);
    } else {
      this.imageTarget.cropper.setAspectRatio(ratio);
    }
    // Temporary solution while above not working in Safari
    this.imageTarget.cropper.canvas.innerHTML = this.imageTarget.cropper.image.outerHTML
  }
}
