import { Controller } from "stimulus";
import Sortable from 'sortablejs';

export default class extends Controller {

 static targets = ["sortable"]

  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this
    
    this.sort();
  }

  sort(){
    const url = this.element.dataset.sortableUrl;
    const sortables = this.sortableTargets;
    let draggedDepth = 0;

    // Loop through each nested sortable element
    for (var i = 0; i < sortables.length; i++) {
      new Sortable(sortables[i], {
        group: {
        name: 'nested',
        pull: function (to, from) {
          const parent1 = to.el.parentElement.closest(".lp-sortable-item");
          let toLvl = 0;

          if (parent1 !== null){
            toLvl = 1  
            const parent2 = parent1.parentElement.closest(".lp-sortable-item")
            if (parent2 !== null){
              toLvl = 2  
              const parent3 = parent2.parentElement.closest(".lp-sortable-item")
              if (parent3 !== null){
                toLvl = 3  
              } 
            }
          }
          if(toLvl > 2) {
            return false;
          }
          if(toLvl == 1 && draggedDepth > 1) {
            return false;
          }
          if(toLvl == 2 && draggedDepth > 0) {
            return false;
          }

          if(from.el.dataset.type !== to.el.dataset.type){
            return false;
          }

          return true;
        }
      },
        animation: 150,
        fallbackOnBody: true,
        swapThreshold: 0.65,
        direction: 'vertical',
        handle: ".lp-sort-handle",
        onMove: function (/**Event*/evt, /**Event*/originalEvent) {
          draggedDepth = evt.dragged.querySelectorAll('.lp-nested-items > *').length;
        },
        // Changed sorting within list
        onEnd: function (/**Event*/evt) {

          let order = [];
          const elementID = evt.item.dataset.id;
          const elementPosition = evt.newIndex;
          const elementParent = evt.item.parentElement.closest(".lp-sortable-item") ? evt.item.parentElement.closest(".lp-sortable-item") : null;
          const elementParentId = elementParent ? elementParent.dataset.id : null;


          // Prevents unintended clicking
          //evt.item.querySelectorAll('.item-link')[0].classList.remove('.pointer-events-none')
          
          // Push to order array
          sortables.forEach(listGroup => {
            const items = listGroup.querySelectorAll('.lp-sortable-item');

            for (let [i, item] of items.entries()) {
              const id = item.dataset.id;
              const type = item.dataset.type;
              const parentId = item.parentElement.closest(".lp-sortable-item") ? item.parentElement.closest(".lp-sortable-item").dataset.id : null;

              order.push({ id: id, type: type, position: elementID == id ? elementPosition  : i, parent: elementID == id ? elementParentId : parentId });
            }
          })

          console.log(evt.pullMode)

          // Send order array to Pages controller
          const data = { order: order };

          fetch(url, {
            method: 'PATCH',
            headers: {
              Accept: "text/vnd.turbo-stream.html",
              'Content-Type': 'application/json',
              
            },
            body: JSON.stringify(data),
          })
          .then((response) => response.text())
          .then(html => Turbo.renderStreamMessage(html))
        },
      });
    }
  }
}
