import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["text"]

  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this
  }

  // Remove element
  copy() {

    // Copy to clipboard
    this.textTarget.select();
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
  }
}
