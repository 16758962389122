import { Controller } from "stimulus";

export default class extends Controller {

 static targets = ["items"]

  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this
    
    this.order();
  }

  order(){
    const iframe = document.querySelector('#lp-editor-frame');
    let iframeContents = (iframe.contentWindow || iframe.contentDocument);
    if (iframeContents.document) iframeContents = iframeContents.document;
    const elementId = this.element.dataset.elementId;
    const objectType = this.element.dataset.objectType;
    const order = JSON.parse(this.element.dataset.order);
    const parent = iframeContents.querySelector(elementId);
    
    order.forEach(item => {
      const id = objectType == "template" ? `#section-${item["id"]}` : `#block-${item["id"]}`;
      const position = item["position"];
      const element = parent.querySelector(id);

      element.dataset.position = position
    });

    let toSort = parent.children;
    toSort = Array.prototype.slice.call(toSort, 0);

    toSort.sort(function(a, b) {
        return a.dataset.position - b.dataset.position
    });

    parent.innerHTML = "";

    for(var i = 0, l = toSort.length; i < l; i++) {
        parent.appendChild(toSort[i]);
    }
  }
}
