import { Controller } from "stimulus"
import Dropzone from "dropzone";

export default class extends Controller {

  static targets = ["drop", "existing", "previews", "template", "blank"]

  connect() {

    const upload = this.dropTarget;
    const url = upload.dataset.url;
    const method = upload.dataset.method
    const maxFiles = upload.dataset.maxFiles;
    const maxFilesize = upload.dataset.maxFileSize;
    const acceptedFiles = upload.dataset.acceptedFiles;
    const authToken = document.querySelector('meta[name="csrf-token"]').content;
    const existingItems = this.existingTarget;
    const previewsContainer = this.previewsTarget;
    const previewsTemplate = this.templateTarget;

    console.log("maxFilesize", maxFilesize)

    // Initialize Dropzone
    const myDropzone = new Dropzone(upload, {
      url: url,
      method: method,
      headers: {
        //Accept: "text/vnd.turbo-stream.html", // For Turbo stream
      },
      maxFiles: maxFiles,
      maxFilesize: parseInt(maxFilesize),
      acceptedFiles: acceptedFiles,
      previewsContainer: previewsContainer,
      thumbnailWidth: 90,
      thumbnailHeight: 90,
      thumbnailMethod: 'crop',
      params: {
        'authenticity_token': authToken
      },
      previewTemplate: previewsTemplate.innerHTML,
      dictFileTooBig: "This file is too big",
      dictInvalidFileType: "This file is invalid",
      dictResponseError: "An error occured",
      dictMaxFilesExceeded: "You can not upload anymore files",
      filesizeBase: 1024,
      uploadMultiple: maxFiles > 1,
      parallelUploads: 1
    });

    myDropzone.on("addedfile", (file) => {
      // if max number of files is 1 or less.
      if( maxFiles < 2 ){
        existingItems.remove();
        previewsContainer.querySelectorAll('.lp-existing-asset').forEach(asset => asset.remove());
        if (myDropzone.files[1]!=null){
          myDropzone.removeFile(myDropzone.files[0]);
        }
      } else {
        if(this.hasBlankTarget){
          const blank = this.blankTarget;
          blank.remove();
        }
      }
    });

    myDropzone.on("processing", (file) => {

      
      file.previewElement.querySelectorAll('.progress-target')[0].classList.remove('hidden')

    });

    myDropzone.on("error", (file, message) => {
      file.previewElement.querySelectorAll('.progress-target')[0].classList.add('hidden')
      //file.previewElement.querySelectorAll('.error-target')[0].classList.remove('hidden')

      console.log("message", message)
    });

    myDropzone.on("success", (file, response) => {

      const assetId = maxFiles > 1 ? response.attachedFiles[0].id : response.id
      const refreshUrl = maxFiles > 1 ? response.attachedFiles[0].refresh : response.refresh
      
        if (response.method == "upload_avatar"){
          document.querySelectorAll(`.user-${response.objectId}-avatar`).forEach(avatar => {
            // Uncomment to change avatar on upload.
            //avatar.change.changeURL(response.file);
          })
        } else if (response.method == "upload_asset"){
          if (response.type == "logo"){
            const iframe = document.querySelector('#previewIframe');
            let iframeContents = (iframe.contentWindow || iframe.contentDocument);
            if (iframeContents.document) iframeContents = iframeContents.document;
            const imageContainer = iframeContents.querySelector('#website-logo-image-container');
            const imageHeight = imageContainer.dataset.height;
            const text = iframeContents.querySelector('#website-logo-text');
            const settingsDropdown = document.querySelector(`#${response.dropdown}`);
            imageContainer.innerHTML = `<img src="${response.file}" class="lp-logo" style="height:${imageHeight}px">`;
            imageContainer.classList.remove('hidden');
            text.classList.add('hidden');
            settingsDropdown.classList.remove('hidden', 'opacity-0');
            file.previewElement.querySelector(".delete a").setAttribute('data-key', response.dropdown);
          }
          const formButtons = parent.document.getElementById('form-buttons-container');

          formButtons.clickAppear.showHiddenElement();
        }
        //file.previewElement.asset.success(response.id, response.edit, response.delete);
        file.previewElement.id = assetId

        fetch(refreshUrl, {
          method: 'PATCH',
          headers: {
            Accept: "text/vnd.turbo-stream.html",
            'Content-Type': 'application/json',
            
          },
        })
        .then((response) => response.text())
        .then(html => Turbo.renderStreamMessage(html))
        .then(data => {
        });
    });

    myDropzone.on("complete", (file) => {
      //file.previewElement.asset.complete();
    });
  }
}
