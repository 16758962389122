import { Controller } from "stimulus";
let debounce = require('lodash/debounce');

export default class extends Controller {
  static targets = ["field"]

  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this
    //this.autosave = debounce(this.autosave, 500).bind(this)

    // This used for prev and next audits to refresh frame

    const update = this.element.dataset.update
    
    if (this.hasFieldTarget && update === "true"){
      this.updateFrame();
    }
  }

  autosave(){
    const form = this.element.closest('form');

    form.formSubmit.submit();
  }

  updateFrame(editor = false){
    const style = this.element.dataset.style;
    const type = this.element.dataset.type;
    const field = this.fieldTarget
    const value = field.value;
    const startValue = this.element.dataset.startValue;
    const src = this.element.dataset.src;
    const optionValues = "optionValues" in this.element.dataset ? JSON.parse(this.element.dataset.optionValues) : null;
    const htmlContainers = this.element.querySelectorAll(".lp-html")
    const html = htmlContainers.length ? htmlContainers[0].innerHTML : null;

    if ("element" in this.element.dataset){
      const element = this.element.dataset.element;
      let elementArray = []

      // Prefix selectors
      element.split(", ").forEach(selector => {
        elementArray.push("." + selector)
      });
      
      const elementModified = elementArray.join(", ");
      const iframe = document.querySelector('#lp-editor-frame');


      if (editor == true){
        const elements = document.querySelectorAll(elementModified);   
        
        this.elementUpdate(elements, style, type, field, value, startValue, src, optionValues, html);
      }

      if (iframe){
        let iframeContents = (iframe.contentWindow || iframe.contentDocument);
        if (iframeContents.document) iframeContents = iframeContents.document;
        const elements = iframeContents.querySelectorAll(elementModified);      

        this.elementUpdate(elements, style, type, field, value, startValue, src, optionValues, html);
      }
      
      // Remove HTML container
      htmlContainers.forEach(container => {
        container.remove();
      });
    }
  }

  elementUpdate(elements, style, type, field, value, startValue, src, optionValues, html, iframeContents){
    elements.forEach((element) => {
      
      const nearestSection = element.closest(".lp-section");
      
      if (nearestSection){
        nearestSection.scrollIntoView({ behavior: 'smooth' });
      }
      if (["radio"].includes(type)){
        let fieldValue = startValue
        this.fieldTargets.forEach(field => {
          if (field.checked == true){
            fieldValue = field.value
          }
        });
        if (["radio_styles_that_dont_involve_classes"].includes(style)){

        } else {
          element.classList.remove(...optionValues);
          element.classList.add(fieldValue);
          if (["style"].includes(style)){
            const body = element.closest("body");
            const manualDarkMode = body.classList.contains("lp-dark-mode");
            const manualLightMode = body.classList.contains("lp-light-mode");
            const browserDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
            const mode = browserDarkMode ? manualLightMode ? "light" : "dark" : manualDarkMode ? "dark" : "light";
            const colorSchemeData = body.querySelector("#color-scheme-data");

            // Update frame
            colorSchemeData.colorScheme.updateFrame(mode);
          }
        }
      } else if (["select-form"].includes(type)){
        const params = { form_token: value }
        const url = this.element.dataset.url + "?" + new URLSearchParams(params);
        
        fetch(url, {
          method: 'PATCH',
        })
        .then(response => response.json())
        .then(json => {
          const html = json.html;
          
          element.innerHTML = html;
        })
      } else if (["font-select"].includes(type)){
        const fontClass = `lp-font-${value}`;

        // Remove all font classes
        element.classList.remove(...optionValues);

        // Add selected font class
        element.classList.add(fontClass);
      } else if (["range"].includes(type)){
        if (["font-size"].includes(style)){
          const scale = element.dataset.scale;

          element.setAttribute("data-size", value);
          element.style.fontSize = (value * (scale / 100)) + "px";
        } else if (["line-height"].includes(style)){
          element.style.lineHeight = value + "em";
          element.querySelectorAll('p, li').forEach(tag => {
            tag.style.lineHeight = value + "em"
          });
        } else if (["font-size-scale"].includes(style)){
          const size = element.dataset.size;

          if ("size" in element.dataset) {
            element.setAttribute("data-scale", value);
            element.style.fontSize = (size * (value / 100)) + "px";
          } else {
            element.style.fontSize = value + "%";
          }

                    
        } else if (["letter-spacing"].includes(style)){
          element.style.letterSpacing = value + "em";
        } else if (["width"].includes(style)){
          element.style.width = value + "px";
          if (element.classList.contains('lp-avatar')) {
            element.style.height = value + "px";
          }
        } else if (["page-width"].includes(style)){
          element.style.maxWidth = value + "px";
        } else if (["margin-top"].includes(style)){
          element.style.marginTop = value + "px";
        } else if (["margin-bottom"].includes(style)){
          element.style.marginBottom = value + "px";
        } else if (["padding-top"].includes(style)){
          element.style.paddingTop = value + "px";
        } else if (["gaps"].includes(style)){
          element.style.gap = value + "px";
        } else if (["padding-bottom"].includes(style)){
          element.style.paddingBottom = value + "px";
        } else if (["card-padding"].includes(style)){
          if(element.classList.contains("lp-card")){
            element.style.padding = value + "px";
          }
        } else if (["auto-play-delay"].includes(style)){
          const swiper = element.swiper;
          const delay = value * 1000;

          swiper.params.autoplay.delay = delay;
        } else if (["border-thickness"].includes(style)){
          element.style.borderWidth = value + "px";
        } else if (["border-radius"].includes(style)){
          element.style.borderRadius = value + "px";
        } else if (["shadow-opacity"].includes(style)){
          const x = element.dataset.shadowX
          const y = element.dataset.shadowY
          const b = element.dataset.shadowB
          const o = value

          element.setAttribute("data-shadow-o", o);
          element.style.boxShadow = `${x}px ${y}px ${b}px rgba(0,0,0,${o})`;
        } else if (["shadow-horizontal-offset"].includes(style)){
          const x = value
          const y = element.dataset.shadowY
          const b = element.dataset.shadowB
          const o = element.dataset.shadowO

          element.setAttribute("data-shadow-x", x);
          element.style.boxShadow = `${x}px ${y}px ${b}px rgba(0,0,0,${o})`;
        } else if (["shadow-vertical-offset"].includes(style)){
          const x = element.dataset.shadowX
          const y = value
          const b = element.dataset.shadowB
          const o = element.dataset.shadowO

          element.setAttribute("data-shadow-y", y);
          element.style.boxShadow = `${x}px ${y}px ${b}px rgba(0,0,0,${o})`;
        } else if (["shadow-blur"].includes(style)){
          const x = element.dataset.shadowX
          const y = element.dataset.shadowY
          const b = value
          const o = element.dataset.shadowO

          element.setAttribute("data-shadow-b", b);
          element.style.boxShadow = `${x}px ${y}px ${b}px rgba(0,0,0,${o})`;
        } else if (["count"].includes(style)){
          element.outerHTML = html
        } else if (["width"].includes(style)){
          element.style.width = value + "px";
        } else if (["height"].includes(style)){
          element.style.height = value + "px";
        
        } else if (["image-width"].includes(style)){
          element.setAttribute("data-width", value);
          if(element.classList.contains("lp-image-custom")){
            element.style.width = value + "px";
          }
        } else if (["image-height"].includes(style)){
          element.setAttribute("data-height", value);
          if(element.classList.contains("lp-image-custom")){
            element.style.height = value + "px";
          }
        }
      } else if (["select"].includes(type)){
        if (["menu-select"].includes(style)){
          element.innerHTML = html
        } else if (["blog-select"].includes(style)){
          element.outerHTML = html
        } else {
          if (["image-size"].includes(style)){
            
            if(value == "lp-image-custom"){
              
              const width = element.dataset.width;
              const height = element.dataset.height;

              element.style.width = width + "px";
              element.style.height = height + "px";
            } else {
              element.style.width = "";
              element.style.height = "";
            }
            console.log("element", element);
            console.log("value", value);
          }
          // Remove all font classes
          element.classList.remove(...optionValues);

          console.log("element", element)

          // Add selected font class
          element.classList.add(value);

          console.log("value", value);

        }
      } else if (["upload"].includes(type)){
        if (["logo"].includes(style)){
          element.innerHTML = html;
        } else if (["upload-image"].includes(style)){
          console.log("html", html)
          element.outerHTML = html;
        }
      } else if (["check_box"].includes(type)){
        if (["separator-line"].includes(style)){
          element.classList.toggle("lp-separator-line");
        } else if (["button-arrow"].includes(style)){
          element.classList.toggle("lp-show-button-arrow");
        } else if (["social-media-icons"].includes(style)){
          element.classList.toggle("lp-show-social-media-icons");
        } else if (["payment-icons"].includes(style)){
          element.classList.toggle("lp-show-payment-icons");
        } else if (["payment-icons"].includes(style)){
          element.classList.toggle("lp-show-payment-icons");
        } else if (["policy-links"].includes(style)){
          element.classList.toggle("lp-show-policy-links");
        } else if (["auto-play"].includes(style)){
          const swiper = element.swiper;
          if(field.checked){
            swiper.autoplay.start();
          } else {
            swiper.autoplay.stop();
          }
        } else if (["button", "dark-mode-toggle"].includes(style)){
          element.classList.toggle("hidden");
          console.log("element", element)
          if (["dark-mode-toggle"].includes(style)){
            if(element.querySelectorAll("input")[0].checked){
              element.click();
            }
            element.closest("body").classList.remove("lp-dark-mode");
            field.clickAppear.refreshLightDarkMode();
          }
        } else if (["featured-images"].includes(style)){
          element.classList.toggle("lp-hide-featured-images");
        } else if (["date"].includes(style)){
          element.classList.toggle("lp-hide-date");
        } else if (["author"].includes(style)){
          element.classList.toggle("lp-hide-author");
        } else if (["tags"].includes(style)){
          element.classList.toggle("lp-hide-tags");
        } else if (["view-all"].includes(style)){
          element.classList.toggle("lp-hide-view-all");
        } else if (["logo-avatar"].includes(style)){
          const width = element.dataset.width;

          element.classList.toggle("lp-avatar");
          element.classList.toggle("lp-border");

          if(field.checked){
            element.style.height = width + "px";
          } else {
            element.style.height = "auto";
          }
        } 
      } else if (["text"].includes(type)){
        if (["social-link"].includes(style)){

          if (value == null || value == ""){
            element.classList.add("hidden");
          } else {
            element.classList.remove("hidden");
          }
        }
      } else if (["text-editor"].includes(type)){
        const elementContainer = element.closest(".lp-element") ? element.closest(".lp-element") : element;
        const strippedValue = value.replace(/<[^>]*>?/gm, '');
        const noValue = (strippedValue == null || strippedValue == "")
        
        element.innerHTML = value;

        console.log("field", field);
        console.log("value", value);
        console.log("elementContainer", elementContainer);
        console.log("noValue", noValue);
        if(noValue){
          elementContainer.classList.add("hidden");
        } else {
          elementContainer.classList.remove("hidden");
        }
        console.log("elementContainerUpdated", elementContainer);
      } else if (["color-scheme-select"].includes(type)){
        const body = element.closest("body");
        const manualDarkMode = body.classList.contains("lp-dark-mode");
        const manualLightMode = body.classList.contains("lp-light-mode");
        const browserDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
        const mode = browserDarkMode ? manualLightMode ? "light" : "dark" : manualDarkMode ? "dark" : "light";
        const colorSchemeData = body.querySelector("#color-scheme-data");
        const fontClass = `lp-color-${value}`;

        // Remove all font classes
        element.classList.remove(...optionValues);

        // Add selected font class
        element.classList.add(fontClass);

        // Update element color scheme data attribute
        element.setAttribute("data-color-scheme", value);

        // Update frame
        colorSchemeData.colorScheme.updateFrame(mode);
      }
    });
  }
}
