import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "clickElement", "appearElement", "icon", "changeElement", "hideElement"]

  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this
  }

  // Fade in
  fadeIn() {

    // Show/hide
    this.appearElementTarget.classList.remove("hidden");

    // Fade
    setTimeout(() => {
      this.appearElementTarget.classList.remove("-translate-y-3");
      this.appearElementTarget.classList.remove("opacity-0");
    });

    // Switch icon
    if (this.hasIconTarget) {
      this.iconTarget.classList.remove("li-angle-down");
      this.iconTarget.classList.add("li-angle-up");
    }
  }

  // Fade out
  fadeOut() {

    // Fade
    this.appearElementTarget.classList.add("-translate-y-3");
    this.appearElementTarget.classList.add("opacity-0");

    // Switch icon
    if (this.hasIconTarget) {
      this.iconTarget.classList.remove("li-angle-up");
      this.iconTarget.classList.add("li-angle-down");
    }

    // Hide
    setTimeout(() => {
      this.appearElementTarget.classList.add("hidden");
    }, 300);
  }

  // Fade out
  fadeOutInstant() {

    // Fade
    this.appearElementTarget.classList.add("-translate-y-3");
    this.appearElementTarget.classList.add("opacity-0");

    // Switch icon
    if (this.hasIconTarget) {
      this.iconTarget.classList.remove("li-angle-up");
      this.iconTarget.classList.add("li-angle-down");
    }

    // Hide
    this.appearElementTarget.classList.add("hidden");
  }

  showHiddenElement(){
    // Hide appear element.
    this.appearElementTarget.classList.add("opacity-0");
    setTimeout(() => {
      this.appearElementTarget.classList.add("hidden");
      
    });

    // Show hidden element.
    this.hideElementTarget.classList.remove("hidden");
    setTimeout(() => {
      this.hideElementTarget.classList.remove("opacity-0");
    });
  }

  hideHiddenElement(){
    // Appear
    this.appearElementTarget.classList.remove("opacity-0");
    setTimeout(() => {
      this.appearElementTarget.classList.remove("hidden");
    });

    // Hide
    this.hideElementTarget.classList.add("opacity-0");
    setTimeout(() => {
      this.hideElementTarget.classList.add("hidden");
    });
  }
  


  // Fade out
  fadeOutWithoutEvent(event) {
    event.preventDefault();

    this.fadeOut();
  }

  showAppearElementWithEvent2(event) {

    if (this.clickElementTarget === event.target || this.element.contains(event.target)) {
      this.toggleAppear();
    } else {
      this.fadeOut();
    }
  }

  // Fade in and ignore event if element is clicked, otherwise fade out/hide.
  showAppearElement(event) {

    if (this.clickElementTarget === event.target || this.clickElementTarget.contains(event.target)) {
      event.preventDefault();

      this.toggleAppear();
      this.clickElementTarget.classList.add("lp-active")
      
    } else {
      this.clickElementTarget.classList.remove("lp-active")
      this.fadeOut();
    }
  }

  openAppearElement(event) {
    if (this.clickElementTarget === event.target || this.clickElementTarget.contains(event.target)) {
      event.preventDefault();

      this.fadeIn();
      this.clickElementTarget.classList.add("lp-active")
    } else {
      this.clickElementTarget.classList.remove("lp-active")
      this.fadeOut();
      this.changeElementTarget.value = this.element.dataset.name
    }
  }

  // Fade in and ignore event.
  showAppearElementWithoutClickout(event) {

    if (this.clickElementTarget === event.target || this.clickElementTarget.contains(event.target)) {
      event.preventDefault();

      this.fadeIn();
    }
  }

  showModal(event) {
    event.preventDefault();
    const modalId = event.currentTarget.closest('.lp-app').dataset.modalId;

    const appsContainer = event.currentTarget.closest('.lp-apps-container')
    appsContainer.querySelector(`#${modalId}`).clickAppear.fadeIn();
  }

  // Fade in if element is clicked, otherwise fade out/hide.
  showAppearElementWithEvent(event) {

    if (this.clickElementTarget === event.target || this.clickElementTarget.contains(event.target)) {
      this.fadeIn();
    } else {
      this.fadeOut();
    }
  }

  toggleAppear(){
    if (this.appearElementTarget.classList.contains('hidden')) {
      this.fadeIn();
    } else {
      this.fadeOut();
    }
  }

  moveUpAppear(){
    this.appearElementTarget.classList.remove("hidden");
    setTimeout(() => {
      this.appearElementTarget.classList.add("-translate-y-3");
      this.appearElementTarget.classList.remove("opacity-0");
    });
  }

  moveUp(){
    // Move back up
    this.appearElementTarget.classList.toggle("-translate-y-3");

    // Fade out
    this.appearElementTarget.classList.add("opacity-0");

    // Hide
    setTimeout(() => {
      this.appearElementTarget.classList.add("hidden");
      this.element.classList.remove("lp-active");
    }, 150);
  }

  // With move down fade and icon change
  moveDownAppear(event){
    if (this.appearElementTarget.classList.contains('hidden')) {

      // Show/hide
      this.appearElementTarget.classList.remove("hidden");
      this.element.classList.add("lp-active");

      // Fade
      setTimeout(() => {
        this.appearElementTarget.classList.remove("-translate-y-3");
        this.appearElementTarget.classList.remove("opacity-0");
      });
    } else {

      this.moveUp();
    }

    // Switch icon
    if (this.hasIconTarget) {
      if (this.iconTarget.classList.contains('li-angle-up')) {
        this.clickElementTarget.classList.add("lp-closed");
        this.clickElementTarget.classList.remove("lp-open");
        this.iconTarget.classList.remove("li-angle-up");
        this.iconTarget.classList.add("li-angle-down");
      } else if (this.iconTarget.classList.contains('li-angle-down')) {
        this.clickElementTarget.classList.remove("lp-closed");
        this.clickElementTarget.classList.add("lp-open");
        this.iconTarget.classList.remove("li-angle-down");
        this.iconTarget.classList.add("li-angle-up");
      }
    }
  }

  // moveDownAppear without event
  appearAndCloseOthers(event) {
    event.preventDefault();
    this.element.parentElement.children.forEach(child => {

      if(child !== this.element){
        child.clickAppear.moveUp();
      }
    })
    this.moveDownAppear();
  }

  // moveDownAppear without event
  moveDownAppearWithoutEvent(event) {
    event.preventDefault();

    this.moveDownAppear();
  }

  openTabCloseOthers(event) {
    event.preventDefault();

    const tabs = this.element.closest("form").querySelectorAll(".lp-tab");
    
    tabs.forEach(tab => {
      if(tab !== this.element){
        tab.clickAppear.moveUp();
      }
    });

    this.moveDownAppear();   
  }

  // Opens the side panel
  openSidePanel(event) {
    document.querySelector('#side').sidePanel.open();
  }

  // Opens the side panel
  removeAllSizesSidePanel(event) {
    document.querySelector('#side').sidePanel.removeAllSizes();
  }

  openSidePanelFromIframe() {
    const iframe = document.querySelector('#previewIframe');

   if (iframe === null){
    parent.document.querySelector('#side').sidePanel.openFromIframe(this.clickElementTarget.dataset.sideUrl)
   } else {
    document.querySelector('#side').sidePanel.open()
   }
  }

  inIframe(e) {
      try {
          return window.self !== window.top;
      } catch (e) {
          return true;
      }
  }

  openSidePanelLarge(e){
    if (this.inIframe(e)){
      parent.document.querySelector('#side').sidePanel.openFromIframe(this.clickElementTarget.dataset.sideUrl, "large")
    } else { 
      document.querySelector('#side').sidePanel.openLarge();
    }
  }

  openSidePanelSmall(e){
    if (this.inIframe(e)){
      parent.document.querySelector('#side').sidePanel.openFromIframe(this.clickElementTarget.dataset.sideUrl, "small")
    } else { 
      document.querySelector('#side').sidePanel.openSmall();
    }
  }

  setSidePanelLarge(){
    document.querySelector('#side').sidePanel.setLarge();
  }

  setSidePanelFull(){
    document.querySelector('#side').sidePanel.setFull();
  }

  removeSidePanelFull(){
    document.querySelector('#side').sidePanel.removeFull();
  }
  
  openSidePanelWithoutOverlay() {
    const iframe = document.querySelector('#previewIframe');   
    if (iframe === null){
      if(this.clickElementTarget.classList.contains('lp-design-viewable')){
        if(this.clickElementTarget.classList.contains('lp-design-view-active')){
          parent.document.querySelector('#side').sidePanel.openFromIframe(this.clickElementTarget.dataset.sideUrl)
        }
      } else {
        parent.document.querySelector('#side').sidePanel.openFromIframe(this.clickElementTarget.dataset.sideUrl)
      }
    } else {
      document.querySelector('#side').sidePanel.openWithoutOverlay();
    } 
  }

  // Opens the side panel
  setSidePanelUrl(event) {
    const url = this.clickElementTarget.dataset.sidePanelUrl;
    document.querySelector('#side').sidePanel.setLocation(url);
  }

  // Opens the side panel
  closeSidePanel() {
    document.querySelector('#side').sidePanel.close();
  }

  closeSidePanelWithoutOverlay(event) {
    document.querySelector('#side').sidePanel.closeWithoutOverlay()
  }

  // Opens modal
  openModal(event) {
    event.preventDefault();
    document.querySelector('#modal').sidePanel.openModal()
  }

  changeURL(url){
    this.changeElementTarget.src = url
  }

  openTabMultiple(event){
    const id = event.currentTarget.dataset.id;
    
    const contents = document.querySelectorAll(`.${id}`);

    // Hide all tabs
    this.appearElementTargets.forEach(element => element.classList.add('hidden', '-translate-y-3', 'opacity-0'));
    
    if (id.includes("single-payment") || id.includes("subscription") || id.includes("freebie") || id.includes("customer-decides")) {
      const amountField = this.changeElementTarget.querySelectorAll('input')[0];

      // Show/hide amount container
      if (id.includes("customer-decides")){
        this.changeElementTarget.classList.add('hidden', '-translate-y-3', 'opacity-0');
      } else if (id.includes("single-payment") || id.includes("subscription") ||  id.includes("freebie")) {
        this.changeElementTarget.classList.remove('hidden', '-translate-y-3', 'opacity-0');
      }

      // Disable/Enable amount field
      if (id.includes("freebie")){
        
        amountField.value = 0;
        amountField.disabled = true;
      } else if (id.includes("single-payment") || id.includes("subscription") || id.includes("customer-decides")) {
        amountField.value = amountField.dataset.value;
        amountField.disabled = false;
      }
    }

    if (contents){
      contents.forEach(content => {
        // Show matching content
        content.classList.remove('hidden');

        // Fade
        setTimeout(() => {
          content.classList.remove("-translate-y-3");
          content.classList.remove("opacity-0");
        });
      });  
    }
  }

  openTab(id, closeAll = true, enableInputs = false){
    const content = document.getElementById(id);

    // Hide all tabs
    if(closeAll){
      this.appearElementTargets.forEach(element => {
        if (content){
          const inputs = content.querySelectorAll(".lp-input");
          inputs.forEach(input => {
            input.disabled = true
          })
        }

        element.classList.add('hidden', '-translate-y-3', 'opacity-0');
      });
    }

    if (content){
      // Show matching content
      content.classList.remove('hidden');

      // Fade
      setTimeout(() => {
        content.classList.remove("-translate-y-3");
        content.classList.remove("opacity-0");
      });

      if(enableInputs){
        const inputs = content.querySelectorAll(".lp-input");
  
        console.log("inputs", inputs)
        
        inputs.forEach(input => {
          input.disabled = false
        });
      }
    }
  }

  closeTab(event){

    const id = event.currentTarget.dataset.id;
    const tab = document.getElementById(id);
    const settingsPanelId = id === "menu-item-settings-level2" ? "settings-panel-level2" : "settings-panel"
    const settingsPanel = document.getElementById(settingsPanelId);

    if (tab){
      // Show matching content
      tab.classList.add("-translate-y-3");
      tab.classList.add("opacity-0");

      // Fade
      setTimeout(() => {
        tab.classList.add('hidden');
      }, 200);

      // Clear panel contents so next load is not congested.
      if (settingsPanel){
        settingsPanel.src = "";
        settingsPanel.innerHTML = "";
      }
    }
  }

  closeOrOpen(){

    const id = this.clickElementTarget.dataset.id;
    const triggerValue = this.clickElementTarget.dataset.triggerValue;
    const value = this.clickElementTarget.value
    const tab = document.getElementById(id);

    if (value === triggerValue){
      // Show matching content
      tab.classList.add("-translate-y-3");
      tab.classList.add("opacity-0");

      // Fade
      setTimeout(() => {
        tab.classList.add('hidden');
      }, 200);
    } else {
      // Show matching content
      tab.classList.remove('hidden');

      // Fade
      setTimeout(() => {
        tab.classList.remove("-translate-y-3");
        tab.classList.remove("opacity-0");
      });
    }
  }

  openTabStandard(event){
    const id = event.currentTarget.dataset.id;
    this.openTab(id);
  }

  openTabNoClose(event){
    const id = event.currentTarget.dataset.id;
    this.openTab(id, false);
  }
  

  openTabButton(event){
    const id = event.currentTarget.dataset.id;
    this.openTab(id);
  }

  openTabInput(){
    const id = `${this.clickElementTarget.value}-${this.clickElementTarget.dataset.id}`;

    console.log("id", id)
    this.openTab(id, true, true);
  }

  openTabRadio(){
    let value;
    this.clickElementTargets.forEach(element => {
      if (element.checked){
        value = element.value;
      }
    });
    this.openTab(value, true, true);
  }

  colorPresetChange(){
    const preset = this.clickElementTarget.value;
    const colors = this.clickElementTarget.dataset.colors;
    const colorsArray = colors.split(", ");
    const sections = document.querySelectorAll('.website-fonts section');

    document.querySelector('#palette-field').value = preset;

    colorsArray.forEach((color, index) => {
      document.querySelector(`#palette-color-${index + 1}`).value = color;

      sections.forEach(section => {
        this.sectionColorChange(section, `color-${index + 1}`, color)
      });
    });
  }

  singleColorChange(){
    const sections = document.querySelectorAll('.website-fonts section');
    const preset = this.clickElementTarget.dataset.preset;
    const color = this.clickElementTarget.value;
    sections.forEach(section => {
      this.sectionColorChange(section, preset, color)
    });
  }

  sectionColorChange(section, presetColor, color){
    const theme = section.dataset.theme;
    const texts = ["h1", "h2", "h3", "h4", "p", ".miscellaneous-style", "time"];
    const borders = ["article"];
    const accentColor = ["a:not(.not-color-links)", ".lp-color-links"];
    const accentBackgroundColor = ["a.btn.btn-solid:not(.not-button-color)"];

    if (presetColor == "color-1"){
      if (["lightest"].includes(theme)){
        document.querySelector('.iframe-content-area').style.backgroundColor = color;
        section.style.backgroundColor = color;    
      } else if (["dark", "darkest"].includes(theme)){
        const selectors = section.querySelectorAll(texts)
        
        selectors.forEach(selector => {
          selector.style.color = color;
        });
      }   
    } else if (presetColor == "color-2"){
      if (["lightest", "dark"].includes(theme)){
        const selectors = section.querySelectorAll(borders)
        
        selectors.forEach(selector => {
          selector.style.borderColor = color;
        });
      } else if (["light"].includes(theme)){
        document.querySelector('.iframe-content-area').style.backgroundColor = color;
        section.style.backgroundColor = color;
      }
    } else if (presetColor == "color-3"){
      if (["lightest", "light", "dark", "darkest"].includes(theme)){

        const selectors = section.querySelectorAll(accentColor);
        const selectors2 = section.querySelectorAll(accentBackgroundColor);
        
        selectors.forEach(selector => {
          selector.style.color = color;
        });

        selectors2.forEach(selector => {
          selector.style.backgroundColor = color;
        });
      }
    } else if (presetColor == "color-4"){
      if (["light", "darkest"].includes(theme)){
        const selectors = section.querySelectorAll(borders)
        
        selectors.forEach(selector => {
          selector.style.borderColor = color;
        });
      } else if (["dark"].includes(theme)){
        document.querySelector('.iframe-content-area').style.backgroundColor = color;
        section.style.backgroundColor = color;
      } 
    } else if (presetColor == "color-5"){
      if (["darkest"].includes(theme)){
        document.querySelector('.iframe-content-area').style.backgroundColor = color;
        section.style.backgroundColor = color;
      } else if (["lightest", "light"].includes(theme)){
        const selectors = section.querySelectorAll(texts)
      
        selectors.forEach(selector => {
          selector.style.color = color;
        });
      } 
    }
  }

  dropdown(){

    this.appearElementTarget.style.height = this.appearElementTarget.offsetHeight
    this.appearElementTarget.classList.toggle("lp-closed");

  }

  toggleClassToElement(){
    const selector = this.element.dataset.selector
    this.element.classList.toggle(selector)
  }

  showEditor(){
    const editor = parent.document.querySelector("#lp-site-editor");

    editor.siteEditor.removeFullscreenView();
  }

  toggleLightDarkMode(){
    const body = document.body;
    const colorSchemeData = body.querySelector("#color-scheme-data");
    
    body.classList.remove("lp-light-mode", "lp-dark-mode");
    if(this.element.checked){
      body.classList.add("lp-dark-mode");
      colorSchemeData.colorScheme.updateFrame("dark")
    } else {
      body.classList.add("lp-light-mode");
      colorSchemeData.colorScheme.updateFrame("light");
    }
  }


  refreshLightDarkMode(){
    const iframe = document.querySelector('#lp-editor-frame');
    let iframeContents = (iframe.contentWindow || iframe.contentDocument);
    if (iframeContents.document) iframeContents = iframeContents.document;
    const body = iframeContents.body;
    const manualDarkMode = body.classList.contains("lp-dark-mode");
    const manualLightMode = body.classList.contains("lp-light-mode");
    const browserDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    const mode = browserDarkMode ? manualLightMode ? "light" : "dark" : manualDarkMode ? "dark" : "light";
    const colorSchemeData = body.querySelector("#color-scheme-data");

    colorSchemeData.colorScheme.updateFrame(mode)
  }

  showPage(e){
    e.preventDefault();

    const frame = parent.document.querySelector("#main-page");
    const url = this.element.dataset.url;

    frame.src = url
  }

  showData(){
    const data = this.element.dataset.data;
    const value = this.clickElementTarget.value;
    const display = JSON.parse(data)[value];
    const elements = this.appearElementTargets;

    elements.forEach(element => {
      element.innerHTML = display;
    })
  }
}
