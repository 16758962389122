import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["progress", "errorMark", "successMark", "size", "edit", "delete"]

  connect() {

    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this
  }

  success(){
    this.successMarkTarget.classList.remove('hidden');
  }

  complete(){
    this.progressTarget.classList.add('hidden');
    this.sizeTarget.classList.remove('hidden');
  }

  processing(){
    this.progressTarget.classList.remove('hidden');
  }

  error(){
    this.errorMarkTarget.classList.remove('hidden');
  }
}
