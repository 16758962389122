import { Controller } from "stimulus";

export default class extends Controller {

  static targets = [ "header", "height" ]

  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this
    
    const headerHeight = this.headerTarget.offsetHeight;
    //this.heightTarget.style.height = headerHeight + "px";
  }
}
