import { Controller } from 'stimulus'
import Chart from 'chart.js/auto';

export default class extends Controller {

  connect() {
    this.load();
  }

  load(){
    
    // Globals
    Chart.defaults.font.family = "Inter";
    Chart.defaults.color = "#6b7280";
    Chart.defaults.borderColor = "#3f3f46";

    const ctx = this.element;
    const data = JSON.parse(ctx.dataset.chartData);
    const title = ctx.dataset.title;

    const myChart = new Chart(ctx, {
      plugins: [{
        afterDraw: chart => {
          if (chart.tooltip?._active?.length) {
            let x = chart.tooltip._active[0].element.x;
            let yAxis = chart.scales.y;
            let ctx = chart.ctx;
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, yAxis.top);
            ctx.lineTo(x, yAxis.bottom);
            ctx.lineWidth = 1;
            ctx.setLineDash([3,3]);
            ctx.strokeStyle = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? '#52525b' : '#d1d5db';
            ctx.stroke();
            ctx.restore();
          }
        }
      }],      
        type: data.type,
        data: {
          datasets: data.datasets,
      },
        options: {
          onHover: (event, chartElement) => {
            event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
          },
          interaction: {
              intersect: false,
              mode: 'index'
          },
          plugins: {
            legend: {
              display: false,
              position: 'top',
              align: 'center',
              labels: {
                padding: 15,
                boxWidth: 5,
                boxHeight: 5,
                usePointStyle: true
              },
            },
            tooltip: {
              // Disable the on-canvas tooltip
              enabled: false,
              backgroundColor: "#18181b",
              borderWidth: 1,
              borderColor: "#3f3f46",
              padding: 15,
              callbacks: {
                title: function(context) {                   
                    return title;
                },
              },
              external: function(context) {
                  // Tooltip Element
                  let tooltipEl = document.getElementById('chartjs-tooltip');

                  // Create element on first render
                  if (!tooltipEl) {
                      tooltipEl = document.createElement('div');
                      tooltipEl.id = 'chartjs-tooltip';
                      tooltipEl.innerHTML = '<div id="lp-tooltip-inner" class="bg-color p-3 rounded-md border border-color text-sm shadow-sm"></div>';
                      document.body.appendChild(tooltipEl);
                  }

                  // Hide if no tooltip
                  const tooltipModel = context.tooltip;
                  if (tooltipModel.opacity === 0) {
                      tooltipEl.style.opacity = 0;
                      return;
                  }
                  
                  // Class
                  tooltipEl.classList.add('relative', 'z-50');

                  // Set caret Position
                  tooltipEl.classList.remove('above', 'below', 'no-transform');
                  if (tooltipModel.yAlign) {
                      tooltipEl.classList.add(tooltipModel.yAlign);
                  } else {
                      tooltipEl.classList.add('no-transform');
                  }

                  function getBody(bodyItem) {
                      return bodyItem.lines;
                  }

                  // Set Text
                  if (tooltipModel.body) {
                      const titleLines = tooltipModel.title || [];
                      
                      let innerHtml = '<div class="tooltip-header flex items-center">';
                      innerHtml += '<h3 class="font-circular">' + titleLines[0] + '</h3>';
                      if(data.datasets.length === 2){
                        const currentValue = Object.values(data.datasets[0].data[tooltipModel.dataPoints[0].parsed.x])[1];
                        const lastValue = Object.values(data.datasets[1].data[tooltipModel.dataPoints[0].parsed.x])[1];
                        const decreaseValue = currentValue - lastValue;
                        const percentageDifferance = Math.round((decreaseValue / lastValue) * 100);

                        if (isFinite(percentageDifferance) && percentageDifferance !== 0){
                          innerHtml += `<div class="status ml-3 ${(percentageDifferance < 0) ? "status-red" : "status-green"}">`;
                          if (percentageDifferance !== 0){
                            innerHtml += `<i class="${(percentageDifferance < 0) ? "li-regular li-arrow-down" : "li-regular li-arrow-up"} mr-0.5"></i>`;
                          }
                          innerHtml += '<span>' + Math.abs(percentageDifferance) + '%</span>';
                          innerHtml += '</div>'; 
                        }
                      }
                      innerHtml += '</div>';
                      innerHtml += '<div class="tooltip-body mt-3 text-xs">';
                      
                      data.datasets.forEach(set => {
                        const date = Object.values(set.data[tooltipModel.dataPoints[0].parsed.x])[0];
                        const value = Object.values(set.data[tooltipModel.dataPoints[0].parsed.x])[1];
                        const color = set.color;
                        const length = 20;
                        const label = set.label.length > length ? `${set.label.substring(0,length)}...` : set.label;
                        const percentageObjects = ["Conversion rate"];

                        innerHtml += '<div class="flex items-center mt-2">';
                        innerHtml += `<div>`;
                        innerHtml += `<div class="w-2 h-2 rounded-full" style="background-color: ${color};"></div>`;
                        innerHtml += `</div>`;
                        innerHtml += `<div class="ml-2 w-full flex justify-between items-center">`;
                        innerHtml += `<span class="text-secondary ">${`${label} &ndash; `}${date}</span>`;
                        innerHtml += `<span class="ml-5">${value}${percentageObjects.some(el => titleLines[0].includes(el))  ? "%" : ""}</span>`;
                        innerHtml += `</div>`;
                        innerHtml += '</div>';
                      });
                    
                      innerHtml += '</div>';
                      
                      let tableRoot = tooltipEl.querySelector('#lp-tooltip-inner');
                      tableRoot.innerHTML = innerHtml;
                  }

                  const position = context.chart.canvas.getBoundingClientRect();
                  const widthPercentage = tooltipModel.caretX / myChart.chartArea.width * 100
                  const breakpoint = 50;
                  const gap = 20;

                  // Display, position, and set styles for font
                  tooltipEl.style.opacity = 1;
                  tooltipEl.style.position = 'absolute';
                  tooltipEl.style.left = widthPercentage > breakpoint ? position.left + tooltipModel.caretX - tooltipEl.offsetWidth - gap + 'px' : position.left + tooltipModel.caretX + gap + 'px';
                  tooltipEl.style.right = 'auto';
                  tooltipEl.style.top = document.documentElement.scrollTop + position.top + 'px';
                  tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
                  tooltipEl.style.pointerEvents = 'none';
              }
            },
          },
          elements: {
            point: {
              radius: 0,
              hitRadius: 10,
              hoverBorderWidth: 3,
              hoverRadius: 2,
            }
          },
          layout: {
            autoPadding: false
          },
          scales: {
              y: {
                  stacked: data.type === "bar" ? true : false,
                  beginAtZero: true,
                  grid: {
                    display: false,
                    borderDash: [3, 3],
                    drawBorder: false,
                    drawTicks: false,
                  },
                  ticks: {
                    padding: 0,
                    stepSize: 1,
                    maxTicksLimit: 7
                  },
                  display: false,
                  grace: data.type === "bar" ? 0 : 1
              },
              x: {
                  stacked: data.type === "bar" ? true : false,
                  grid: {
                    display: false,
                    borderDash: [3, 3],
                    drawBorder: true,
                    borderColor: window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? '#52525b' : '#d1d5db',
                    drawTicks: false,
                  },
                  ticks: {
                    align: "inner",
                    padding: 15,
                    maxRotation: 0,
                    callback: function(value, index, values) {
                      if(index === 0 || index === data.datasets[0].data.length - 1){
                        return data.datasets[0].data[index].label
                      }
                    }
                  },
                  afterFit: (axis) => {
                    axis.paddingRight = 0;
                    axis.paddingLeft = 0;
                  }
              },
          }
        }
    });
    if(data.datasets.length === 2){
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
        const newColorScheme = e.matches ? "dark" : "light";
        const appearanceSetting = document.documentElement.dataset.appearance
  
        if (newColorScheme == "dark" && appearanceSetting == '' || newColorScheme == "dark" && appearanceSetting == 'device' || appearanceSetting == 'dark') {
        myChart.data.datasets[1].borderColor = '#52525b';
        myChart.data.datasets[1].color = '#52525b';
        myChart.data.datasets[1].pointBorderColor = '#52525b';
        myChart.update();
        } else {
          myChart.data.datasets[1].borderColor = "#d1d5db";
          myChart.data.datasets[1].color = '#52525b';
          myChart.data.datasets[1].pointBorderColor = '#52525b';
          myChart.update();
        }
      }); 
    }
    
  }
}
