import { Controller } from "stimulus";
import Litepicker from 'litepicker/dist/nocss/litepicker.umd.js';
import 'litepicker/dist/nocss/plugins/ranges';

export default class extends Controller {
  
    connect() {
        const startDate = this.element.dataset.startDate;
        const minDate = this.element.dataset.minDate;
        const singleMode = this.element.dataset.singleMode;
        const inlineMode = this.element.dataset.inlineMode;
        const numberOfColumns = this.element.dataset.numberOfColumns;
        const numberOfMonths = this.element.dataset.numberOfMonths;
        const d = new Date();
        const accountStart = new Date(this.element.dataset.accountStart * 1000);
        const ranges = this.element.dataset.ranges;
        const submit = this.element.dataset.submit;
        const position = this.element.dataset.position;
        const loader = this.element.dataset.loader;

        function getFirstDayOfMonth(year, month) {
            return new Date(year, month, 1);
        }

        const picker = new Litepicker({ 
            element: this.element,
            startDate: startDate,
            minDate: minDate,
            singleMode: (singleMode === 'true'),
            inlineMode: (inlineMode === 'true'),
            numberOfColumns: Number(numberOfColumns),
            numberOfMonths: Number(numberOfMonths),
            format: "D MMM, YYYY",
            delimiter: " — ",
            position: position,
            zIndex: 999999999999,
            setup: (picker) => {
                picker.on('selected', (date1, date2) => {
                    const form = this.element.closest('form');

                    if(loader === "true"){
                        this.element.closest('.load-container').load.showAllLoaders();
                    }
                    
                    if(submit === "true"){
                        form.formSubmit.submit();
                    }
                    
                });
             },
             plugins: (ranges === 'true' ? ['ranges'] : null),
             ranges: {
                customRanges: {
                    'Today': [d, d],
                    'Last 7 days': [new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), d],
                    'Last 30 days': [new Date(Date.now() - 30 * 24 * 60 * 60 * 1000), d],
                    'Last 3 months': [new Date(d.getFullYear(),d.getMonth() - 3, d.getDate()), d],
                    'Last 12 months': [new Date(d.getFullYear(),d.getMonth() - 12, d.getDate()), d],
                    'Month to date': [getFirstDayOfMonth(d.getFullYear(), d.getMonth()), d],
                    'Quarter to date': [getFirstDayOfMonth(d.getFullYear(), Math.floor((d.getMonth() + 3) / 3)), d],
                    'Year to date': [getFirstDayOfMonth(d.getFullYear(), 0), d],
                    'All time': [accountStart, d],
                }
            }
        });
    }
}