import { Controller } from 'stimulus'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {

  static targets = ["optionsContainer", "options", "content"]

  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this
    useClickOutside(this)
  }

  clickOutside(event) {
    this.hideOptionContainer();
    this.unfocus();
  }

  showOptionContainer(){
    document.querySelectorAll('.lp-block').forEach(block => {
      block.element.hideOptionContainer();
      block.element.unfocus();
    });
    this.contentTarget.classList.add('lp-focus');
    this.optionsTarget.classList.remove("opacity-0");
  }

  hideOptionContainer(){

    // Option container
    this.optionsTarget.classList.add("opacity-0");
  }
  
  unfocus(){

    // Option container
    this.contentTarget.classList.remove("lp-focus");
  }

  disableItems(){
    setTimeout(() => {
      document.querySelectorAll('.lp-block').forEach(block => {
        block.classList.add('pointer-events-none')
      });
      this.element.classList.remove('pointer-events-none');
    }, 150);
  }

  moveUp(){
    const elem = this.element;
    const parentElem = elem.parentElement;
    const elemIndex = Array.prototype.indexOf.call(parentElem.children, elem);
    
    // Move
    parentElem.insertBefore(elem, parentElem.children[elemIndex - 1]);

    // Update element sort attribute
    this.sort(parentElem);
  }

  moveDown(){
    const elem = this.element;
    const parentElem = elem.parentElement;
    const elemIndex = Array.prototype.indexOf.call(parentElem.children, elem);
    
    // Move
    parentElem.insertBefore(elem, parentElem.children[elemIndex + 2]);

    // Update element sort attribute
    this.sort(parentElem);
  }

  sort(parentElem){
    let order = [];
    const url = parentElem.dataset.blocksSortUrl;
    const blocks = parentElem.querySelectorAll('.lp-block');
    const formButtons = parent.document.getElementById('form-buttons-container');

    for (let [i, block] of blocks.entries()) {
      const id = block.dataset.id;

      order.push({ id: id, position: i });
    }

    // Send order array to controller
    const data = { order: order };

    fetch(url, {
      method: 'PATCH',
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        'Content-Type': 'application/json',
        
      },
      body: JSON.stringify(data),
    })
    .then((response) => response.text())
    .then(html => Turbo.renderStreamMessage(html))
    .then(data => {
      formButtons.clickAppear.showHiddenElement();
    });

  }
}
