import { Controller } from "stimulus";

export default class extends Controller {

  static targets = [ "page", "side"]

  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this
  }

  toggleMobileView() {
    this.pageTarget.classList.toggle("mobile-view");
  }

  mobileView(event) {
    const iframeContainer = document.getElementById('previewIframeContainer');
    const mobileViewButtons = iframeContainer === null ? parent.document.querySelectorAll('.lp-mobile-view-button') : document.querySelectorAll('.lp-mobile-view-button');

    // Toggle mobile view
    if (iframeContainer === null){
      //this.toggleMobileView();  
    } else {
      iframeContainer.classList.toggle("mobile-view");
      /*
      let iframeContents = (iframe.contentWindow || iframe.contentDocument);
      if (iframeContents.document) iframeContents = iframeContents.document;
      iframeContents.querySelector('body').layout.toggleMobileView();
      */
    }
    
    // Make button active
    mobileViewButtons.forEach(button => button.classList.toggle("active"));
  }

  enterMobileView(event) {
    const iframeContainer = document.getElementById('previewIframeContainer');
    const mobileViewButtons = iframeContainer === null ? parent.document.querySelectorAll('.lp-mobile-view-button') : document.querySelectorAll('.lp-mobile-view-button');

    // Toggle mobile view
    if (iframeContainer === null){
      //this.toggleMobileView();  
    } else {
      iframeContainer.classList.add("mobile-view");
      /*
      let iframeContents = (iframe.contentWindow || iframe.contentDocument);
      if (iframeContents.document) iframeContents = iframeContents.document;
      iframeContents.querySelector('body').layout.toggleMobileView();
      */
    }
    
    // Make button active
    mobileViewButtons.forEach(button => button.classList.add("active"));
  }

  exitMobileView(event) {
    const iframeContainer = document.getElementById('previewIframeContainer');
    const mobileViewButtons = iframeContainer === null ? parent.document.querySelectorAll('.lp-mobile-view-button') : document.querySelectorAll('.lp-mobile-view-button');

    // Toggle mobile view
    if (iframeContainer === null){
      //this.toggleMobileView();  
    } else {
      iframeContainer.classList.remove("mobile-view");
      /*
      let iframeContents = (iframe.contentWindow || iframe.contentDocument);
      if (iframeContents.document) iframeContents = iframeContents.document;
      iframeContents.querySelector('body').layout.toggleMobileView();
      */
    }
    
    // Make button active
    mobileViewButtons.forEach(button => button.classList.remove("active"));
  }

  editView(event){
    const link = event.target.dataset.link;
    const editView = parent.document.querySelector('#edit-view')
    const preview = parent.document.querySelector('#preview')

    editView.sidePanel.showPrimaryPanelLink(link);

    setTimeout(() => {
      editView.sidePanel.increasePanelWidth();
      preview.clickActive.unedit();
    }, 100);
  }

  editorView(){
    this.sideTarget.classList.add("ev-side");
    this.pageTarget.classList.add("ev-main");
  }

  editorViewEdit(){
    const iframe = document.querySelector('#previewIframe');
    
    iframe === null ? parent.document.body.layout.editorViewEdit() : this.element.classList.add("design-view", "design-view-side");
  }

  removeEditorView(){
    if (this.element.classList.contains('design-view')){
      this.element.classList.remove("design-view");
      setTimeout(() => {
        this.element.classList.remove("design-view-side");
      }, 200);
    } else if (this.hasPageTarget && this.pageTarget.classList.contains('ev-main') && this.sideTarget.classList.contains('ev-side')){
      this.pageTarget.classList.remove("ev-main");
      setTimeout(() => {
        this.sideTarget.classList.remove("ev-side");
      }, 200);
    }
    document.querySelector('#form-buttons-container').clickAppear.hideHiddenElement();
  }

  editFromEditor(){
    if (this.element.classList.contains('design-view')){
      this.element.classList.remove("design-view");
      setTimeout(() => {
        this.element.classList.remove("design-view-side");
      }, 200);
    } else if (this.hasPageTarget && this.pageTarget.classList.contains('ev-main') && this.sideTarget.classList.contains('ev-side')){
      this.pageTarget.classList.remove("ev-main");
      setTimeout(() => {
        this.sideTarget.classList.remove("ev-side");
      }, 200);
    }
  }
}
