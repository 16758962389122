import { Turbo } from "@hotwired/turbo-rails";
import { Controller } from "stimulus";


export default class extends Controller {

  static targets = [ "panel", "panelInner", "overlay", "modal", "view", "primaryPanel", "secondaryPanel", "preview", "menu", "button"]

  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this
  }

  // Opens the side panel.
  open(){

    // Show loader
    this.load();
          
    // Panel
    this.panelTarget.classList.remove("hidden");
    setTimeout(() => {
      this.panelTarget.classList.remove("translate-x-full");
    });

    // Overlay
    this.overlayTarget.classList.remove("hidden");
    setTimeout(() => {
      this.overlayTarget.classList.remove("opacity-0");
    });
  }

  // Opens and closes the side panel.
  toggle(){

    document.documentElement.wholePage.toggleDisableScroll();

    if(this.panelTarget.classList.contains("hidden") && this.overlayTarget.classList.contains("hidden")){
      // Button
      this.buttonTarget.classList.add("is-active");

       // Panel
      this.panelTarget.classList.remove("hidden");
      setTimeout(() => {
        this.panelTarget.classList.remove("-translate-x-full");
      });

      // Overlay
      this.overlayTarget.classList.remove("hidden");
      setTimeout(() => {
        this.overlayTarget.classList.remove("opacity-0");
      });
    } else {
      // Button
      this.buttonTarget.classList.remove("is-active");

      // Panel
      this.panelTarget.classList.add("-translate-x-full");
      setTimeout(() => {
        this.panelTarget.classList.add("hidden");
      }, 200);

      // Overlay
      this.overlayTarget.classList.add("opacity-0");
      setTimeout(() => {
        this.overlayTarget.classList.add("hidden");
      }, 200);
    }
          
   
  }

  load(){
    // Show loader
    this.viewTarget.innerHTML = "<div class='loader loader-xl opacity-100'></div>";
  }

  reloadFrame(){
    this.viewTarget.src = this.viewTarget.src
  }

  loadWithText(){
    
    // Show loader
    this.viewTarget.innerHTML = "<div class='loader loader-xl opacity-100'></div>";
  }


  openLarge(){
    this.open();

    // Panel
    this.setLarge();
  }

  openSmall(){
    this.open();

    // Panel
    this.setSmall();
  }

  setMid(){
    // Panel
    this.panelInnerTarget.classList.add("side-md");
  }

  setLarge(){
    // Panel
    this.panelInnerTarget.classList.add("side-lg");
  }

  setSmall(){
    // Panel
    this.panelInnerTarget.classList.add("side-sm");
  }

  removeLarge(){
    // Panel
    this.panelInnerTarget.classList.remove("side-lg");
  }

  removeAllSizes(){
    this.panelInnerTarget.classList.remove("side-lg", "side-sm", "side-md");
  }

  setFull(){

    // Panel
    this.panelInnerTarget.classList.add("side-full");
  }

  removeFull(){
    // Panel
    this.panelInnerTarget.classList.remove("side-full");
  }
  
  // Closes the side panel
  close(){
    // Button
    if(this.hasButtonTarget){
      document.documentElement.wholePage.toggleDisableScroll();

      this.buttonTarget.classList.remove("is-active");
    }
    
    // Panel
    if(this.panelTarget.classList.contains("lp-side-left")){
      this.panelTarget.classList.add("-translate-x-full");
    } else {
      this.panelTarget.classList.add("translate-x-full");
    }
    setTimeout(() => {
      this.panelTarget.classList.add("hidden");
      
      // Size
      if(this.hasPanelInnerTarget){
        this.panelInnerTarget.classList.remove("side-lg", "side-sm", "side-full");
      }
    }, 200);

    // Overlay
    this.overlayTarget.classList.add("opacity-0");
    setTimeout(() => {
      this.overlayTarget.classList.add("hidden");
    }, 200);
  }

  // Opens the side panel.
  openWithoutOverlay(){

    // Show loader
    this.load();

    // Panel
    this.panelTarget.classList.remove("hidden");
    
    setTimeout(() => {
      this.panelTarget.classList.remove("translate-x-full");
    });
  }

  // Opens the side panel.
  openFromIframe(side_url, size){

    if (size === "large"){
      this.setLarge();
    } else if (size === "small"){
      this.setSmall();
    }

    // Show loader
    this.load();

    this.viewTarget.setAttribute('src', side_url);

    // Panel
    this.panelTarget.classList.remove("hidden");
    setTimeout(() => {
      this.panelTarget.classList.remove("translate-x-full");
    });

    // Overlay
    this.overlayTarget.classList.remove("hidden");
    setTimeout(() => {
      this.overlayTarget.classList.remove("opacity-0");
    });
    
  }
  
  // Closes the side panel
  closeWithoutOverlay(){

    // Panel
    this.panelTarget.classList.add("translate-x-full");
    setTimeout(() => {
      this.panelTarget.classList.add("hidden");
      // Size
      this.panelInnerTarget.classList.remove("side-lg", "side-sm", "side-full");
    }, 200);
  }

  // Sets side panel view url
  setLocation(url){
    this.viewTarget.setAttribute('src', url)
  }

  openModal(){

    // Panel
    this.modalTarget.classList.remove("hidden");
    setTimeout(() => {
      this.modalTarget.classList.remove("opacity-0");
    });

    // Overlay
    this.overlayTarget.classList.remove("hidden");
    setTimeout(() => {
      this.overlayTarget.classList.remove("opacity-0");
    }, 200);
  }

  showSecondaryPanel(){
    // Primary panel
    this.primaryPanelTarget.classList.add("opacity-0", "-translate-x-5");
    setTimeout(() => {
      this.primaryPanelTarget.classList.add("hidden");
    }, 200);

    // Secondary panel
    this.secondaryPanelTarget.classList.remove("hidden");
    setTimeout(() => {
      this.secondaryPanelTarget.classList.remove("opacity-0", "-translate-x-5");
    }, 200);
  }

  showPrimaryPanel(){
    // Primary panel
    this.secondaryPanelTarget.classList.add("opacity-0", "-translate-x-5");
    setTimeout(() => {
      this.secondaryPanelTarget.classList.add("hidden");
    }, 200);

    // Secondary panel
    this.primaryPanelTarget.classList.remove("hidden");
    setTimeout(() => {
      this.primaryPanelTarget.classList.remove("opacity-0", "-translate-x-5");
    }, 200);
  }
  

  showPrimaryPanelLink(link){
    this.showPrimaryPanel();

    fetch(link, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
  }

  increasePanelWidth(){
    this.previewTarget.classList.add("w-8/12");
    this.previewTarget.classList.remove("w-3/4");

    this.menuTarget.classList.add("w-4/12");
    setTimeout(() => {
      this.menuTarget.classList.remove("w-1/4");
    });
  }
  decreasePanelWidth(){
    this.previewTarget.classList.remove("w-8/12");
    this.previewTarget.classList.add("w-3/4");

    this.menuTarget.classList.remove("w-4/12");
    setTimeout(() => {
      this.menuTarget.classList.add("w-1/4");
    });
  }
}
