import { Controller } from "stimulus";
import Sortable from 'sortablejs';

export default class extends Controller {

 static targets = ["items"]

  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this

    const elementId = this.element.dataset.elementId;
    const iframe = document.querySelector('#lp-editor-frame');
    
    if (iframe){
      let iframeContents = (iframe.contentWindow || iframe.contentDocument);
      if (iframeContents.document) iframeContents = iframeContents.document;
      
      const element = iframeContents.querySelector(elementId); 
      
      if(element !== null){
        element.classList.remove("lp-focus");
      }
    }
  }

  addFocus(){
    const elementId = this.element.dataset.elementId;
    const iframe = document.querySelector('#lp-editor-frame');
    

    let iframeContents = (iframe.contentWindow || iframe.contentDocument);
    if (iframeContents.document) iframeContents = iframeContents.document;

    console.log("elementId", elementId)
    
    const element = iframeContents.querySelector(elementId); 
    
    if(element !== null){

      console.log("element", element)

      element.classList.add("lp-focus");
    
      if(element.classList.contains('swiper-slide')){
        
        const swiperElement = element.closest(".swiper");
        const index = element.dataset.swiperSlideIndex;
        const swiper = swiperElement.swiper;

        swiper.slideTo(index);
      }
    }
  }

  removeFocus(){
    const elementId = this.element.dataset.elementId;
    const iframe = document.querySelector('#lp-editor-frame');
    

    let iframeContents = (iframe.contentWindow || iframe.contentDocument);
    if (iframeContents.document) iframeContents = iframeContents.document;
    
    const element = iframeContents.querySelector(elementId); 
    
    if(element !== null){
      element.classList.remove("lp-focus");
    }
  }

  addFocusFrame(){
    const menuId = this.element.dataset.menuId;  
    const editor = parent.document;
    const menuItem = editor.querySelector(menuId); 
    
    if(menuItem !== null){
      menuItem.querySelectorAll('.lp-link')[0].classList.add("lp-focus");
      menuItem.scrollIntoView({ behavior: 'smooth' });
    }
  }

  removeFocusFrame(){
    const menuId = this.element.dataset.menuId;  
    const editor = parent.document;
    const menuItem = editor.querySelector(menuId); 
    
    if(menuItem !== null){
      menuItem.querySelectorAll('.lp-link')[0].classList.remove("lp-focus");
    }
  }

  elementView(){
    const elementId = this.element.dataset.elementId;
    const iframe = document.querySelector('#lp-editor-frame');
    

    let iframeContents = (iframe.contentWindow || iframe.contentDocument);
    if (iframeContents.document) iframeContents = iframeContents.document;
    
    const element = iframeContents.querySelector(elementId); 
    
    if(element !== null && !element.classList.contains('swiper-slide')){
      element.closest(".lp-section").scrollIntoView({ behavior: 'smooth' });
    }
  }

  showSettings(e){
    const siteEditor = parent.document.querySelector("#lp-site-editor");
    const settingsTypeButton = siteEditor.querySelector("#settings-panel-type");
    const settingsFrameSecondary = siteEditor.querySelector("#menu-item-settings-level2");
    const settingsPanelSecondary = settingsFrameSecondary.querySelector("#settings-panel-level2");
    const settingsFrame = siteEditor.querySelector("#settings-panel");
    const settingsUrl = this.element.dataset.settingsUrl;
    const clickedElement = e.target
    const clickedElementLink = e.target.closest("a");
    const isLink = clickedElement.tagName.toLowerCase() === 'a' || (typeof(clickedElementLink) != 'undefined' && clickedElementLink != null);

    console.log("isLink", isLink);

    if (!isLink && settingsFrame.src !== settingsUrl){
      settingsFrame.src = settingsUrl
      // Remove fullscreen view
      siteEditor.siteEditor.removeFullscreenView();

      // Set buttons to sections
      settingsTypeButton.querySelectorAll(".switch-label")[0].click();

      // Show settings tab
      settingsFrame.closest("#lp-editor-side-inner").clickAppear.openTab("menu-item-settings", false)

      // Close Secondary Panel
      settingsFrameSecondary.classList.add("-translate-y-3");
      settingsFrameSecondary.classList.add("opacity-0");
      setTimeout(() => {
        settingsFrameSecondary.classList.add('hidden');
      }, 200);

      // Clear secondary panel contents so next load is not congested.
      settingsPanelSecondary.src = "";
      settingsPanelSecondary.innerHTML = "";
    }
  }

  showPage(){
    const frame = parent.document.querySelector("#main-page");
    const url = this.element.dataset.url;

    frame.src = url

  }

  reInit(){
  }
  
}
