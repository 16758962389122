import { Controller } from "stimulus"
const confetti = require('canvas-confetti');


export default class extends Controller {

  connect() {
    var myCanvas = this.element

    var myConfetti = confetti.create(myCanvas, {
      resize: true,
      useWorker: true
    });
    myConfetti({
      particleCount: 100,
      spread: 160
      // any other options from the global
      // confetti function
    });
  }
}
