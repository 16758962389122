import { Controller } from 'stimulus'
let debounce = require('lodash/debounce');

export default class extends Controller {

  static targets = ["flag", "addressCountryField", "field", "numberField", "nameField", "numberError", "validNumberIcon", "validNameIcon", "numberLoader", "nameLoader", "numberCheckField", "nameCheckField"]

  connect() {
    
    // Initial fields update
    this.updateFields();
    this.validateNumber = debounce(this.validateNumber, 1000).bind(this)
  }

  // Displays a country flag or default icon
  updateFields(){
    const numberField = this.numberFieldTarget;
    const nameField = this.nameFieldTarget;
    const country = this.addressCountryFieldTarget.value;
    const icon = this.flagTarget;
    const url = this.element.dataset.taxDataUrl
    const params = { country: country }

    fetch(url + "?" + new URLSearchParams(params), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.text())
    .then(data => {
      const response = JSON.parse(data);
      const name = response.name;
      const flag = response.flag;
      const minLength = response.minLength;
      const maxLength = response.maxLength;
      const numberFormat = response.numberFormat;
      const regex = response.regex;
      const countryCode = response.countryCode;
      const countryName = response.countryName;
      
      // Update flag
      if (flag != null){
        icon.classList.remove('li-regular li-location-pin-slash');
        icon.innerHTML = `<div class="animate__animated animate__zoomIn">${flag}</div>`;
      } else {
        icon.classList.add('li-regular li-location-pin-slash');
        icon.innerHTML = "";
      }

      // Sets regex data attribute
      numberField.setAttribute('data-regex', regex == null ? "" : regex);

      // Sets format data attribute
      numberField.setAttribute('data-format', numberFormat == null ? "" : numberFormat);

      // Sets country data attributes
      numberField.setAttribute('data-country-code', countryCode == null ? "" : countryCode);
      numberField.setAttribute('data-country-name', countryName == null ? "" : countryName);

      // Sets max & min length attribute
      numberField.setAttribute('minLength', minLength == null ? "" : minLength);
      numberField.setAttribute('maxlength', maxLength == null ? "" : maxLength);

      // Change tax name placeholder
      numberField.placeholder = name == null ? "VAT number" : name

      // Remove current input
      //numberField.value = ""

      // Enable/disable inputs
      numberField.disabled = nameField.disabled = flag == null ? true : false;
    });
  }

  // Validates the number input value.
  validateNumber(){
    const numberField = this.numberFieldTarget;
    const nameField = this.nameFieldTarget;
    const number = numberField.value.replace(/ /g,'').toUpperCase();
    const regex = new RegExp(numberField.dataset.regex);
    const numberValidFormat = regex.test(number);
    const numberLoader = this.numberLoaderTarget;
    const nameLoader = this.nameLoaderTarget;
    const numberError = this.numberErrorTarget;
    const validNumberIcon = this.validNumberIconTarget;
    const validNameIcon = this.validNameIconTarget;
    const key = this.element.dataset.taxKey;
    const idempotency_key = this.element.dataset.taxIdempotencyKey;
    const params = { key: key, target_tax_number: number, target_company_name: nameField.value};
    const url = this.element.dataset.taxApiUrl;
    const numberLength = numberField.value.length;
    const minLength = numberField.minLength;
    const maxLength = numberField.maxLength;
    const numberFormat = numberField.dataset.format;
    const countryName = numberField.dataset.countryName;

    if (this.hasNameFieldTarget){
      const nameField = this.nameFieldTarget;
      const companyName = nameField.value
      params.target_company_name = companyName;
    }

    // Replace number field with formatted number
    if(numberLength){
      numberField.value = number
    }
    
    // Hide valid icon
    validNumberIcon.classList.add('hidden');
    numberLoader.classList.remove('opacity-100');
    validNameIcon.classList.add('hidden');
    nameLoader.classList.remove('opacity-100');

    // Set check fields
    this.numberCheckFieldTarget.value = false;
    this.nameCheckFieldTarget.value = false;

    if (numberLength >= minLength){
      
      if (numberValidFormat === true){

        // Remove errors
        numberField.classList.remove('field_with_errors');
        nameField.classList.remove('field_with_errors');
        numberError.innerHTML = "";
        numberError.classList.add("hidden");

        if (nameField.value.length){

          // Loader while fetching from API
          numberLoader.classList.add('opacity-100');
          nameLoader.classList.add('opacity-100');

          // Temporarily disable number field
          numberField.disabled = true;
          nameField.disabled = true;

          // Fetch API
          fetch(url + "?" + new URLSearchParams(params), {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((response) => response.text())
          .then(data => {
            const response = JSON.parse(data);
            const validNumber = response.valid
            const companyName = response.company_name
            const companyNameMatch = response.company_name_match
            const statusType = response.status

            // Remove numberLoader when response received
            numberLoader.classList.remove('opacity-100');
            nameLoader.classList.remove('opacity-100');

            // Enable number field
            numberField.disabled = false;
            nameField.disabled = false;
            
            // Number field
            if (validNumber){
    
              // Show valid icon
              validNumberIcon.classList.remove('hidden');

              // Set number_check value to false
              if (this.hasNumberCheckFieldTarget){
                this.numberCheckFieldTarget.value = true;
              }

              if (companyName){
                
                // Name field
                if (companyNameMatch === true){
        
                  // Show valid icon
                  validNameIcon.classList.remove('hidden');

                  // Set name_check value to true
                  if (this.hasNameCheckFieldTarget){
                    this.nameCheckFieldTarget.value = true;
                  }
                  
                } else if (companyNameMatch === false) {
                  nameField.classList.add('field_with_errors');
                  numberError.innerHTML = "Your company name does not match with this VAT number.";
                  numberError.classList.remove("hidden");
                }
              }
            } else if (response.valid === false) {
              const errorMessage = statusType === "unavailable" ? "We're currently unable to verify this VAT number due to government database downtime." : "Your VAT number is invalid."
              
              numberField.classList.add('field_with_errors');
              numberError.innerHTML = errorMessage;
              numberError.classList.remove("hidden");
            } else {
              numberField.classList.add('field_with_errors');
              numberError.innerHTML = response.error;
              numberError.classList.remove("hidden");
            }
          });
        }
      } else {
        numberField.classList.add('field_with_errors');
        numberError.innerHTML = `Invalid format. The format for ${countryName} VAT is ${numberFormat}.`;
        numberError.classList.remove("hidden");
      }
    } else {
      numberField.classList.remove('field_with_errors');
      numberError.innerHTML = "";
      numberError.classList.add("hidden");
    }
  }

  nameFocus(){
    const numberField = this.numberFieldTarget;
    const nameField = this.nameFieldTarget;
    const numberLength = numberField.value.length;
    const maxLength = numberField.maxLength;

    if (numberLength === maxLength && nameField !== document.activeElement){
      nameField.focus();
    }
  }

  numberFocus(event){
    const numberField = this.numberFieldTarget;
    const nameField = this.nameFieldTarget;

    if (event.keyCode == 8 && nameField.value.length === 0){
      numberField.focus();
    }
  }
}
